import {
	User,
	AuthenticatedUser,
	UNAUTHENTICATED_USER_TAG,
	AUTHENTICATED_USER_TAG,
	RegisterUserResponse,
} from '../types/userTypes';
import { isAuthenticatedUser } from '../types/userTypes';
import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { EMPTY } from 'common/utils/typeUtils';

// TODO: it might be smarter just to add a 'token' field
// to the user object and do it all on the same slice.
export const userRegistered =
	createAction<RegisterUserResponse>('userRegistered');

// Cast to union type User to prevent createSlice from narrowing to
// UnauthenticatedUser based on the value provided to token in initialState.
// We want the type available in Redux state to be a union.
export const initialState = {
	__tag: UNAUTHENTICATED_USER_TAG,
} as User;

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		authenticate: (state, action: PayloadAction<AuthenticatedUser>) => {
			return action.payload;
		},

		logout: () => {
			return { ...initialState };
		},
	},
	extraReducers: (builder) => {
		builder.addCase(userRegistered, (s, a) => {
			const { token, ...userProps } = a.payload;

			return { __tag: AUTHENTICATED_USER_TAG, ...userProps };
		});
	},
});

export const { authenticate, logout } = userSlice.actions;

export const selectUserAuthStatus = (s: RootState) =>
	isAuthenticatedUser(s.user);

/**Casts result to AuthenticatedUser, since no component using this should be
mounted if user is unauthenticated.  To check User auth status, use
selectUserAuthStatus instead.
*/
export const selectUserProfile = (s: RootState) => s.user as AuthenticatedUser;

export const selectUserProfileImageURL = ({ user }: RootState) => {
	if (isAuthenticatedUser(user)) {
		const maybeURL = user.avatar?.thumb?.url;

		return maybeURL ?? EMPTY;
	}

	return EMPTY;
};

export const selectUserID = ({ user }: RootState) =>
	isAuthenticatedUser(user) ? user._id : EMPTY;

export const selectDefaultAccount = ({ user }: RootState) =>
	isAuthenticatedUser(user) ? user.defaultAccount : EMPTY;

export const selectTOSAccepted = ({ user }: RootState) =>
	isAuthenticatedUser(user) ? user.tosAccepted : false;

export default userSlice.reducer;
