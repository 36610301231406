import Paper from 'common/Paper';
import styled from 'styled-components';

export const StyledVizPaper = styled(Paper)`
	display: flex;
	flex-direction: column;
	margin-top: 5px;
	flex: 1 1 40%;
	height: 100%;
	align-self: flex-start;
	overflow: hidden;
`;

export const StyledSubmoduleHeader = styled.div`
	padding: ${(p) => p.theme.spacing(1)};
	border-bottom: 1px solid ${(p) => p.theme.palette.divider};
`;

export const StyledSubmoduleFooter = styled.div`
	padding: ${(p) => p.theme.spacing(1)};
	border-top: 1px solid ${(p) => p.theme.palette.divider};
`;

export const StyledSubmoduleContent = styled.div`
	flex-grow: 1;
	overflow: hidden;
`;

// IMPORTANT: If overflow is NOT hidden on these flex containers,
// an infinite loop is created by ResizeObserver callback.
// TODO: understand this better, and maybe submit at bug report somewhere(?)
export const StyledScatterplotContent = styled.div`
	flex-grow: 1;
	display: grid;
	grid-template-columns: 4fr 1fr;
	overflow: hidden;
`;

export const StyledAttributeList = styled.div`
	border-left: 1px solid ${(p) => p.theme.palette.divider};
`;

export const StyledPoint = styled.circle<{ hoverScaleFactor: number }>`
	&:hover {
		cursor: pointer;
		transform: scale(
			${(p) => `${p.hoverScaleFactor}, ${p.hoverScaleFactor}`}
		);
		/* this keeps the scale from applying to circle's cx/cy */
		transform-box: fill-box;
		transform-origin: center;
	}
`;
