import environment from '../../common/environment';
import { flow } from '../../common/utils/functionUtils';
import { warnInDev } from '../../common/utils/reactUtils';
import {
	GenDomainFromCatalogParams,
	GenDomainFromDatasetParams,
	GetCatalogDataSetsParams,
	GetCatalogSummariesParams,
	GetLiveDataSetPreviewParams,
	GetSourceSummariesParams, // GenDomainFromSourceParams,
	ImportCatalogToDomainParams,
	ImportDatasetToDomainParams,
} from '../SourceBrowser/types/dataTypes';
import {
	GetUserParams,
	UpdateUserParams,
} from '../authentication/types/userTypes';
import { AggregateAttrActionParams } from '../futuremodelActions/aggregateAttribute/aggregateAttributeTypes';
import { AppendAttrActionParams } from '../futuremodelActions/appendAttribute/appendAttributeTypes';
import { CreateConditionalActionParams } from '../futuremodelActions/createConditional/createConditionalTypes';
import { CreateEventActionParams } from '../futuremodelActions/createEvent/createEventTypes';
import { CreateLocationActionParams } from '../futuremodelActions/createLocation/createLocationTypes';
import { DeleteAttrActionParams } from '../futuremodelActions/deleteAttribute/deleteAttributeTypes';
import { DescribeAttrActionParams } from '../futuremodelActions/describeAttribute/describeAttributeTypes';
import { DescribeDomainParams } from '../futuremodelActions/describeDomain/describeDomainTypes';
import { DescribeEntityParams } from '../futuremodelActions/describeEntity/describeEntityTypes';
import { LoadAttrActionParams } from '../futuremodelActions/loadAttribute/loadAttributeTypes';
import { MeltEntityParams } from '../futuremodelActions/meltEntity/meltEntityTypes';
import { OperateAttrActionParams } from '../futuremodelActions/operateAttribute';
import { PersistEntityParams } from '../futuremodelActions/persistEntity/persistEntityTypes';
import { RelateAttrActionParams } from '../futuremodelActions/relateAttribute/relateAttributeTypes';
import { RestrictEntityActionParams } from '../futuremodelActions/restrictEntity/restrictEntityTypes';
import {
	GetAccountParams,
	GetAccountsParams,
} from '../ontology/types/accountTypes';
import { GetAccountGraphParams } from '../ontology/types/accountTypes';
import {
	GetAttributeParams,
	GetAttributesParams,
} from '../ontology/types/attributeTypes';
import {
	GetEntityAttrsParams,
	GetDomainAttrsParams,
	GetAttrNeighborsParams,
} from '../ontology/types/attributeTypes';
import {
	DeleteDomainParams,
	GetDomainGraphParams,
	GetDomainParams,
	GetDomainsParams,
	UnfollowDomainParams,
} from '../ontology/types/domainTypes';
import { FollowDomainParams } from '../ontology/types/domainTypes';
import {
	GetEntitiesParams,
	GetIndividualsParams,
	GetEntityParams,
	ImportEntityParams,
	UnfollowEntityParams,
	isDeleteImportParams,
} from '../ontology/types/entityTypes';
import {
	FollowEntityParams,
	IdentifyEntityParams,
} from '../ontology/types/entityTypes';
import { DeleteEntityParams } from '../ontology/types/entityTypes';
import { UpdateEntityParams } from '../ontology/types/entityTypes';
import {
	buildParametrizedQuestionsQueryURL,
	buildQuestionsQueryURL,
} from '../questions/helpers';
import {
	AcceptAnswerParams,
	CreateAnswerParams,
	CreateQuestionParams,
	GetQuestionsParams,
	QuestionableObject,
} from '../questions/types/questionTypes';
import {
	CreateAccountAdminParams,
	CreateAccountGovernorParams,
	CreateAccountMemberParams,
	CreateDomainMemberParams,
	CreateDomainStewardParams,
	GetAccountAdminsParams,
	GetAccountGovernorsParams,
	GetAccountMembersParams,
	GetDomainMembersParams,
	GetDomainStewardsParams,
	RemoveUserRoleParams,
} from '../userManagement/types/MemberManagementTypes';
import { ConditionalArgs } from './types';
import { isNonNullObject } from 'common/utils/typeGuards';
import { GetAttributeLineageParams } from 'features/ontology/types/lineageTypes';

const warnOnMissingSeparator = () =>
	warnInDev(
		`remove leading/trailing separator was called without a string argument.  There is likely a broken type somewhere in the application code, and a broken or inaccurate link in the application`,
		'error'
	);

const createWarn = () => {
	let hasWarned = false;

	return () => {
		if (!hasWarned) {
			warnOnMissingSeparator();
			hasWarned = true;
		}

		return;
	};
};

const warn = createWarn();

const removeTrailingSeparator = (separator: string) => (s: string) => {
	if (s === null || typeof s === 'undefined') {
		warn();
		return s;
	}

	while (s[s.length - 1] === separator) {
		s = s.slice(0, s.length - 1);
	}

	return s;
};

const removeLeadingSeparator = (separator: string) => (s: string) => {
	if (s === null || typeof s === 'undefined') {
		warn();
		return s;
	}

	while (s[0] === separator) {
		s = s.slice(1, s.length);
	}
	return s;
};

export const joinOnSeparator =
	(separator: string) =>
	(...args: string[]) => {
		return args
			.map(
				flow(
					removeLeadingSeparator(separator),
					removeTrailingSeparator(separator)
				)
			)
			.join(separator);
	};

export const withLeadingSlash =
	(separator: string) =>
	(...args: string[]) =>
		'/' + joinOnSeparator(separator)(...args);

export const joinOnSlash = joinOnSeparator('/');

const replacer = (s: string, k?: any) =>
	s
		.split('/')
		.map((s) => {
			if (s[0] === ':') {
				const replacementVal = k ? k[s.substring(1)] : null;

				if (!replacementVal) {
					// get the stack into the console
					console.error(
						'Parameter/value mismatch in URL builder\nParams:\n',
						JSON.stringify(k)
					);
					console.error('path:\n', s);
					// break the program
					throw new Error('Parameter/value mismatch in URL builder');
				}

				return replacementVal;
			}

			return s;
		})
		.join('/');

interface BuilderConfig<T, K> {
	urlConstructor: (...params: ConditionalArgs<T>) => string;
	endpointConstructor: (...params: ConditionalArgs<K>) => string;
}

const isBuilderConfig = <T, K>(
	v: string | BuilderConfig<T, K>
): v is BuilderConfig<T, K> => isNonNullObject(v);

// TODO: this should use URL/URLParams constructor rather than this
// homeroll bullshit.
export const makeBuilder =
	(domain: string) =>
	<T = undefined, K = undefined>(
		endpointPath: string | BuilderConfig<T, K>
	) => {
		if (isBuilderConfig(endpointPath)) {
			return {
				buildURL: (...args: ConditionalArgs<T>) =>
					joinOnSlash(domain, endpointPath.urlConstructor(...args)),

				buildSubpath: (...args: ConditionalArgs<T>) =>
					joinOnSlash('/', endpointPath.urlConstructor(...args)),

				endpoint: (...args: ConditionalArgs<K>) =>
					joinOnSlash(
						domain,
						endpointPath.endpointConstructor(...args)
					),
			};
		}

		return {
			buildURL: (...args: ConditionalArgs<T>) =>
				joinOnSlash(domain, replacer(endpointPath, ...args)),

			buildSubpath: (...args: ConditionalArgs<T>) =>
				joinOnSlash('/', replacer(endpointPath, ...args)),

			endpoint: () => joinOnSlash(domain, endpointPath),
		};
	};

const { API_ROOT, USE_API_SERVICE_WORKER } = environment;

const domain = USE_API_SERVICE_WORKER === 'true' ? API_ROOT : '';

const builder = makeBuilder(domain);

const authenticateUser = builder('/tokens');

const refreshToken = builder('/tokens/refresh');

const getUser = builder<GetUserParams>('users/:userId');

const getUsers = builder('users');

const reserveUser = builder('users');

const updateUser = builder<UpdateUserParams>('users/:userId');

const signout = builder('/tokens');

const getAccount = builder<GetAccountParams>('/accounts/:accountId');

const getAccounts = builder<GetAccountsParams>('/users/:userId/accounts');

const getAccountGraph = builder<GetAccountGraphParams>(
	'/accounts/:accountId/graph'
);

const getDomainGraph = builder<GetDomainGraphParams>(
	'/domains/:domainId/graph'
);

const getEntity = builder<GetEntityParams>('/entities/:entityId');

const createEntity = builder('/entities');

const getEntities = builder<GetEntitiesParams>('/domains/:domainId/entities');

const getDomains = builder<GetDomainsParams>('/accounts/:accountId/domains');

const getDomain = builder<GetDomainParams>('/domains/:domainId');

const createDomain = builder('/domains');

const getAttribute = builder<GetAttributeParams>('/attributes/:attributeId');

const getAttributes = builder<GetAttributesParams>(
	'/entities/:entityId/attributes'
);

const getSourceSummaries = builder<GetSourceSummariesParams>(
	'/accounts/:accountId/sources'
);

const getCatalogSummaries = builder<GetCatalogSummariesParams>(
	'/sources/:sourceId/catalogs'
);

const getCatalogDataSets = builder<GetCatalogDataSetsParams>(
	'/sources/:sourceId/catalogs/:catalogName'
);

const createSource = builder('/sources');

const getLiveDataSetPreview = builder<GetLiveDataSetPreviewParams>(
	'/sources/:sourceId/catalogs/:catalogName/datasets/:datasetName/preview'
);

const importCatalogToDomain = builder<ImportCatalogToDomainParams>(
	'/domains/:domainId/sources/:sourceId/catalogs/:catalogName/import'
);

const importDatasetToDomain = builder<ImportDatasetToDomainParams>(
	'/domains/:domainId/sources/:sourceId/catalogs/:catalogName/datasets/:datasetName/import'
);

const genDomainFromCatalog = builder<GenDomainFromCatalogParams>(
	'/sources/:sourceId/catalogs/:catalogName/domain'
);

const genDomainFromDataset = builder<GenDomainFromDatasetParams>(
	'/sources/:sourceId/catalogs/:catalogName/datasets/:datasetName/domain'
);

const followDomain = builder<FollowDomainParams>('/domains/:domainId/follow');

const unfollowDomain = builder<UnfollowDomainParams>(
	'/domains/:domainId/unfollow'
);

const followEntity = builder<FollowEntityParams>('/entities/:entityId/follow');

const unfollowEntity = builder<UnfollowEntityParams>(
	'/entities/:entityId/unfollow'
);

const getEntityAttributes = builder<GetEntityAttrsParams>(
	'/entities/:entityId/attributes'
);

const identifyEntity = builder<IdentifyEntityParams>(
	'/entities/:entityId/identify'
);

const getDomainAttributes = builder<GetDomainAttrsParams>(
	'/domains/:domainId/attributes'
);

const relateAttrAction = builder<RelateAttrActionParams>(
	'/attributes/:sourceAttrId/relate'
);

const describeAttrAction = builder<DescribeAttrActionParams>(
	'/attributes/:sourceAttrId'
);

const appendAttrAction = builder<AppendAttrActionParams>(
	'/attributes/:attrId/operation'
);

const getAttrNeighbors = builder<GetAttrNeighborsParams>(
	'/attributes/:attrId/neighbors'
);

const getIndividuals = builder<GetIndividualsParams>(
	'/entities/:entityId/individuals'
);

// Type parameter is a union of several types that are not mutually-compatible;
// to successfully call the builder we need to narrow the expected type to properties
// that exist on all members of the union
const operateAttrAction = builder<Pick<OperateAttrActionParams, 'attrId'>>(
	'/attributes/:attrId/operation'
);

const restrictEntityAction = builder<
	RestrictEntityActionParams,
	{ type: 'specialization' | 'restriction' }
>({
	urlConstructor: (params) =>
		`entities/${params.entityId}/${
			params.restrictionKind === 'specialization'
				? 'specialize'
				: 'restrict'
		}`,
	endpointConstructor: ({ type }) =>
		type === 'restriction'
			? '/entities/:entityId/restrict'
			: '/entities/:entityId/specialize',
});

const loadAttribute = builder<LoadAttrActionParams>(
	'/attributes/:sourceAttrId/load'
);

const aggregateAttribute = builder<AggregateAttrActionParams>(
	'/attributes/:attributeId/aggregation'
);

const createAttribute = builder('/attributes');

const deleteAttribute = builder<DeleteAttrActionParams>('/attributes/:attrId');

const updateEntity = builder<UpdateEntityParams>('/entities/:entityId');

const describeDomain = builder<DescribeDomainParams>('/domains/:domainId');

const deleteEntity = builder<DeleteEntityParams, { isImport: boolean }>({
	urlConstructor: (params) =>
		isDeleteImportParams(params)
			? `/domains/${params.domainId}/imports/${params.entityId}`
			: `/entities/${params.entityId}`,
	endpointConstructor: ({ isImport }) =>
		isImport
			? '/domains/:domainId/imports/:entityId'
			: '/entities/:entityId',
});

const deleteDomain = builder<DeleteDomainParams>('/domains/:domainId');

const meltEntity = builder<MeltEntityParams>('/entities/:entityId/melt');

const persistEntity = builder<PersistEntityParams>(
	'/entities/:entityId/persist'
);

const importEntity = builder<ImportEntityParams>(
	'/domains/:domainId/entities/:entityId/import'
);

const createConditional = builder<CreateConditionalActionParams>(
	'/entities/:entityId/condition'
);

const requestPWReset = builder('/reset-password-request');

const resetPassword = builder('/reset-password');

const completeRegistration = builder('/complete-registration');

const acceptTOS = builder('/tos');

const inviteUser = builder('/users/invite');

const describeEntity = builder<DescribeEntityParams>('/entities/:entityId');

const createQuestion = builder<CreateQuestionParams>('/questions');

const getQuestions = builder<GetQuestionsParams, QuestionableObject>({
	urlConstructor: buildQuestionsQueryURL,
	endpointConstructor: buildParametrizedQuestionsQueryURL,
});

const createAnswer = builder<CreateAnswerParams>(
	'/questions/:questionId/answers'
);

const acceptAnswer = builder<AcceptAnswerParams>(
	'/questions/:questionId/accept'
);

const getAccountAdmins = builder<GetAccountAdminsParams>(
	'/accounts/:accountId/admins'
);

const createAccountAdmin = builder<CreateAccountAdminParams>(
	'/accounts/:accountId/admins'
);

const getAccountGovernors = builder<GetAccountGovernorsParams>(
	'/accounts/:accountId/governors'
);

const createAccountGovernor = builder<CreateAccountGovernorParams>(
	'/accounts/:accountId/governors'
);

const getAccountMembers = builder<GetAccountMembersParams>(
	'/accounts/:accountId/users'
);

const createAccountMember = builder<CreateAccountMemberParams>(
	'/accounts/:accountId/members'
);

const getDomainStewards = builder<GetDomainStewardsParams>(
	'/domains/:domainId/stewards'
);

const createDomainSteward = builder<CreateDomainStewardParams>(
	'/domains/:domainId/stewards'
);

const getDomainMembers = builder<GetDomainMembersParams>(
	'/domains/:domainId/members'
);

const createDomainMember = builder<CreateDomainMemberParams>(
	'/domains/:domainId/stewards'
);

// NB: add an 's' after 'role' parameter and 'parentObjectType' parameter
const removeUserRole = builder<
	RemoveUserRoleParams,
	{ parentObjectType: 'account' | 'domain' }
>({
	urlConstructor: ({ parentObjectId, parentObjectType, role, userId }) =>
		`${parentObjectType}s/${parentObjectId}/${role}s/${userId}`,
	endpointConstructor: ({ parentObjectType }) =>
		`/${parentObjectType}s/:objectId/:role/:userId`,
});

const createLocation = builder<CreateLocationActionParams>(
	'/entities/:entityId/locations'
);

const createEvent = builder<CreateEventActionParams>(
	'/entities/:entityId/events'
);

const getAttributeLineage = builder<GetAttributeLineageParams>(
	'/attributes/:attributeId/lineage'
);

const completeOnboarding = builder('/onboarding');

const pathBuilders = {
	completeOnboarding,
	getUsers,
	getAttributeLineage,
	createEvent,
	createLocation,
	removeUserRole,
	createDomainMember,
	createDomainSteward,
	createAccountGovernor,
	createAccountMember,
	createAccountAdmin,
	getDomainStewards,
	getDomainMembers,
	getAccountGovernors,
	getAccountMembers,
	getAccountAdmins,
	acceptAnswer,
	createAnswer,
	getQuestions,
	createQuestion,
	describeEntity,
	inviteUser,
	acceptTOS,
	completeRegistration,
	requestPWReset,
	authenticateUser,
	reserveUser,
	getUser,
	updateUser,
	signout,
	getAccount,
	getAccounts,
	getAccountGraph,
	getDomainGraph,
	getDomain,
	createDomain,
	getEntity,
	createEntity,
	getEntities,
	getDomains,
	getAttribute,
	getAttributes,
	getSourceSummaries,
	getCatalogSummaries,
	getCatalogDataSets,
	createSource,
	getLiveDataSetPreview,
	importCatalogToDomain,
	importDatasetToDomain,
	genDomainFromCatalog,
	genDomainFromDataset,
	followDomain,
	unfollowDomain,
	followEntity,
	unfollowEntity,
	getEntityAttributes,
	identifyEntity,
	getDomainAttributes,
	relateAttrAction,
	appendAttrAction,
	describeAttrAction,
	getAttrNeighbors,
	operateAttrAction,
	restrictEntityAction,
	loadAttribute,
	aggregateAttribute,
	deleteAttribute,
	updateEntity,
	deleteEntity,
	createAttribute,
	meltEntity,
	persistEntity,
	deleteDomain,
	importEntity,
	createConditional,
	describeDomain,
	resetPassword,
	refreshToken,
	getIndividuals,
};

export default pathBuilders;
