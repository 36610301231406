import FlexContainer from '../FlexContainer';
import { PopoverContentColumns, PopoverImage } from './styledComponents';
import { FunctionComponent } from 'react';

const PopoverMainContent: FunctionComponent = ({ children }) => {
	return (
		<PopoverContentColumns>
			{/*<FlexContainer justifyContent="start">*/}
				<PopoverImage />
			{/*</FlexContainer>*/}
			<FlexContainer flexDirection="column" style={{ maxWidth: '100%' }}>
				{children}
			</FlexContainer>
		</PopoverContentColumns>
	);
};

export default PopoverMainContent;
