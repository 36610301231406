import useAuthStatus from 'features/authentication/hooks/useAuthStatus';
import useUserProfile from 'features/authentication/hooks/useUserProfile';
import { FunctionComponent } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

interface RequireAuthProps {
	to: string;
	TOSRequired?: boolean;
}

const RequireAuth: FunctionComponent<RequireAuthProps> = ({
	children,
	to,
	TOSRequired,
}) => {
	const { tosAccepted, isOnboarded } = useUserProfile();

	const isUnauthorized = !useAuthStatus();

	const location = useLocation();

	if (isUnauthorized) {
		return (
			<Navigate to={to} state={{ returnWhenAuthenticated: location }} />
		);
	}

	if (TOSRequired) {
		if (tosAccepted && isOnboarded) {
			return <>{children}</>;
		}

		if (!tosAccepted) {
			return <Navigate to="/terms-of-service" />;
		}

		return <Navigate to="/onboarding" />;
	}

	return <>{children}</>;
};

export default RequireAuth;
