import { baseUserContext } from './userContextData';
import { faker } from '@faker-js/faker';
import {
	DataCatalogSummaries,
	DataSourceSummaries,
	LiveDataSet,
} from 'features/SourceBrowser/types/dataTypes';
import { BaseAccount } from 'features/ontology/types/accountTypes';
import {
	BaseAttribute,
	CategoryAttribute,
	EventAttribute,
	IdentityAttribute,
	LocationAttribute,
	QuantityAttribute,
} from 'features/ontology/types/attributeTypes';
import { BaseDomain } from 'features/ontology/types/domainTypes';
import {
	BaseEntity,
	EntityViewFlags,
} from 'features/ontology/types/entityTypes';

// NB: we keep all the 'raw' files stuff in one file b/c it depends on each other, and
// we need to avoid circular imports.

// Account Data
export const davisAssociates: BaseAccount = {
	_id: 17,
	_links: {
		followers: '/api/accounts/1/followers',
		self: '/api/accounts/1',
	},
	_object: 'Organization',
	adminId: 52,
	context: 'davis-associates',
	created: '2020-09-09T01:31:03Z',
	creator: {},
	currency: 'USD',
	label: 'Davis Associates',
	language: 'en-us',
	modified: '2021-12-13T05:59:13Z',
	name: 'davis-associates',
	orgName: null,
	social: {
		facebookId: 'futuremodel.io',
		instagramId: 'futuremodel.io',
		linkedinId: 'futuremodel',
	},
	stats: {
		followers: 1,
	},
	timezone: 'utc',
	userContext: baseUserContext,
	// userContext: {
	// 	...baseUserContext,
	// 	permissions: { edit: false, view: true, admin: false },
	// },
};

export const businessCorp: BaseAccount = {
	_id: 23,
	_links: {
		followers: '/api/accounts/1/followers',
		self: '/api/accounts/1',
	},
	_object: 'Organization',
	adminId: 52,
	context: 'business-corp',
	created: '2020-09-09T02:31:03Z',
	creator: {},
	currency: 'USD',
	label: 'Business Corp',
	language: 'en-us',
	modified: '2021-12-13T06:22:13Z',
	name: 'business-corp',
	orgName: null,
	social: {
		facebookId: 'futuremodel.io',
		instagramId: 'futuremodel.io',
		linkedinId: 'futuremodel',
	},
	stats: {
		followers: 1,
	},
	timezone: 'utc',
	userContext: baseUserContext,
};

// Domain

export const warehouseInventory: BaseDomain = {
	_id: 11,
	_object: 'Domain',
	context: `${davisAssociates.context}:warehouse-inventory`,
	definition: null,
	label: 'Warehouse Inventory',
	name: 'warehouse-inventory',
	objective: null,
	userContext: baseUserContext,
};

export const salesLeads: BaseDomain = {
	_id: 99,
	_object: 'Domain',
	context: `${davisAssociates.context}:sales-leads`,
	definition: faker.lorem.paragraph(5),
	label: 'Sales Leads',
	name: 'sales-leads',
	objective: null,
	userContext: baseUserContext,
	// userContext: {
	// 	...baseUserContext,
	// 	permissions: { edit: false, view: true, admin: false },
	// },
};

export const humanResources: BaseDomain = {
	_id: 10,
	_object: 'Domain',
	context: `${businessCorp.context}:human-resources`,
	definition: null,
	label: 'Human Resources',
	name: 'human-resources',
	objective: null,
	userContext: baseUserContext,
};

// Entity Data
export const baseFlags: EntityViewFlags = {
	hasIdentities: true,
	hasRelations: false,
	hasQuantities: false,
	hasCategories: false,
	hasLocations: false,
	hasEvents: false,
	hasImages: false,
	hasVideos: false,
	hasStart: false,
	hasEnd: false,
};

export const furniture: BaseEntity = {
	_id: 200,
	_object: 'Entity',
	context: `${warehouseInventory.context}:furniture`,
	domain: warehouseInventory._id,
	definition: null,
	name: 'furniture',
	plural: 'Furniture',
	singular: 'Furniture',
	userContext: baseUserContext,
	...baseFlags,
};

export const plant: BaseEntity = {
	_id: 201,
	_object: 'Entity',
	context: `${warehouseInventory.context}:plants`,
	domain: warehouseInventory._id,
	definition: null,
	...baseFlags,
	name: 'plant',
	plural: 'Plants',
	singular: 'Plant',
	userContext: baseUserContext,
};

export const directContact: BaseEntity = {
	...baseFlags,
	_id: 202,
	_object: 'Entity',
	context: `${salesLeads.context}:direct-contact`,
	definition: null,
	domain: salesLeads._id,
	name: 'direct-contact',
	plural: 'Direct Contacts',
	singular: 'Direct Contact',
	hasIdentities: false,
	userContext: baseUserContext,
};

export const phoneContact: BaseEntity = {
	_id: 203,
	_object: 'Entity',
	context: `${salesLeads.context}:phone-contact`,
	domain: salesLeads._id,
	definition: faker.lorem.paragraph(3),
	name: 'phone-contact',
	plural: 'Phone Contacts',
	singular: 'Phone Contact',
	userContext: baseUserContext,
	...baseFlags,
	hasLocations: true,
};

export const hire: BaseEntity = {
	_id: 205,
	_object: 'Entity',
	context: `${humanResources.context}:hire`,
	domain: humanResources._id,
	definition: null,
	name: 'hire',
	plural: 'Hires',
	singular: 'Hire',
	...baseFlags,
	userContext: baseUserContext,
};

export const retirement: BaseEntity = {
	_id: 206,
	_object: 'Entity',
	context: `${humanResources.context}:retirement`,
	definition: null,
	domain: humanResources._id,
	name: 'retirement',
	plural: 'Retirements',
	singular: 'Retirement',
	...baseFlags,
	userContext: baseUserContext,
};

// Attribute Data

export const makeAlias = (entityName: string, attrName: string) => {
	const convert = (s: string) => `${s.split('-').join('_').toUpperCase()}`;

	return `${convert(entityName)}__${convert(attrName)}`;
};

// furniture
export const item_id: BaseAttribute = {
	_id: 298,
	_object: 'Attribute',
	context: `${furniture.context}:id`,
	definition: null,
	isPrimary: true,
	name: 'id',
	plural: 'ids',
	singular: 'id',
	isComposite: false,
	stats: {},
	type: 'quantity',
	hasSource: true,
	alias: makeAlias(furniture.name, 'id'),
	entity: {
		_id: furniture._id,
		_object: 'Entity',
		name: furniture.name,
		singular: 'Furniture',
		plural: 'Furniture',
	},
	userContext: baseUserContext,
};

export const legCount: BaseAttribute = {
	_id: 300,
	alias: makeAlias(furniture.name, 'leg-count'),
	_object: 'Attribute',
	context: `${furniture.context}:leg-count`,
	definition: null,
	isComposite: false,
	isPrimary: false,
	name: 'leg-count',
	plural: 'Leg Count',
	singular: 'Leg Count',
	stats: {},
	type: 'quantity',
	hasSource: true,
	entity: {
		_id: furniture._id,
		_object: 'Entity',
		name: furniture.name,
		singular: 'Furniture',
		plural: 'Furniture',
	},
	userContext: baseUserContext,
};

export const color: BaseAttribute = {
	_id: 301,
	alias: makeAlias(furniture.name, 'color'),
	isComposite: false,
	hasSource: true,
	_object: 'Attribute',
	context: `${furniture.context}:color`,
	definition: null,
	isPrimary: false,
	name: 'color',
	plural: 'Colors',
	singular: 'Color',
	stats: {},
	entity: {
		_id: furniture._id,
		_object: 'Entity',
		name: furniture.name,
		singular: 'Furniture',
		plural: 'Furniture',
	},
	type: 'category',
	userContext: baseUserContext,
};

// plant
export const preferredClimate: BaseAttribute = {
	_id: 302,
	_object: 'Attribute',
	context: `${plant.context}:preferred-climate`,
	definition: null,
	isComposite: false,
	isPrimary: false,
	alias: makeAlias(plant.name, 'preferred-climate'),
	entity: {
		_id: plant._id,
		_object: 'Entity',
		name: plant.name,
		singular: 'Plant',
		plural: 'Plants',
	},
	name: 'preferred-climate',
	plural: 'Preferred Climates',
	singular: 'Preferred Climate',
	stats: {},
	type: 'category',
	hasSource: true,
	userContext: baseUserContext,
};

export const flowering: BaseAttribute = {
	_id: 303,
	_object: 'Attribute',
	context: `${plant.context}:flowering`,
	alias: makeAlias(plant.name, 'flowering'),
	definition: null,
	isComposite: false,
	isPrimary: false,
	name: 'flowering',
	hasSource: true,
	plural: 'Flowerings',
	singular: 'Flowering',
	stats: {},
	entity: {
		singular: 'Plant',
		plural: 'Plants',
		_id: plant._id,
		_object: 'Entity',
		name: plant.name,
	},
	type: 'category',
	userContext: baseUserContext,
};

// directContact
export const contactLocation: BaseAttribute = {
	_id: 304,
	_object: 'Attribute',
	context: `${directContact.context}:contact-location`,
	definition: null,
	isComposite: false,
	isPrimary: false,
	alias: makeAlias(directContact.name, 'contact-location'),
	name: 'contact-location',
	entity: {
		singular: 'Direct Contact',
		plural: 'Direct Contacts',
		_id: directContact._id,
		_object: 'Entity',
		name: directContact.name,
	},
	plural: 'Contact Locations',
	singular: 'Contact Location',
	stats: {},
	type: 'location',
	hasSource: true,
	userContext: baseUserContext,
};

export const contactTime: BaseAttribute = {
	_id: 340,
	_object: 'Attribute',
	context: `${directContact.context}:contact-time`,
	definition: null,
	isComposite: false,
	isPrimary: false,
	name: 'contact-time',
	alias: makeAlias(directContact.name, 'contact-time'),
	hasSource: true,
	entity: {
		singular: 'Direct Contact',
		plural: 'Direct Contacts',
		_id: directContact._id,
		_object: 'Entity',
		name: directContact.name,
	},
	plural: 'Contact Times',
	singular: 'Contact Time',
	stats: {},
	type: 'event',
	userContext: baseUserContext,
};

// phoneContact
export const totalPurchaseValue: QuantityAttribute = {
	_id: 368,
	_object: 'Attribute',
	context: `${phoneContact.context}:total-purchase-value`,
	definition: null,
	alias: makeAlias(phoneContact.name, 'total-purchase-value'),
	isPrimary: false,
	isComposite: false,
	name: 'total-purchase-value',
	plural: 'Total Purchase Values',
	singular: 'Total Purchase Value',
	entity: {
		singular: 'Phone Contact',
		plural: 'Phone Contacts',
		_id: phoneContact._id,
		_object: 'Entity',
		name: phoneContact.name,
	},
	stats: {},
	type: 'quantity',
	hasSource: true,
	userContext: baseUserContext,
	profile: {
		results: {
			max: 20,
			min: 1,
		},
	},
};

// phoneContact
export const websiteLink: IdentityAttribute = {
	_id: 369,
	_object: 'Attribute',
	context: `${phoneContact.context}:website-link`,
	definition: null,
	isComposite: false,
	alias: makeAlias(phoneContact.name, 'website-link'),
	isPrimary: false,
	name: 'website-link',
	plural: 'Website Links',
	singular: 'Website Link',
	entity: {
		singular: 'Phone Contact',
		plural: 'Phone Contacts',
		_id: phoneContact._id,
		_object: 'Entity',
		name: phoneContact.name,
	},
	stats: {},
	type: 'identity',
	hasSource: true,
	userContext: baseUserContext,
	profile: {
		results: {
			maxLength: 200,
			minLength: 50,
		},
	},
};

// phoneContact
export const comment: CategoryAttribute = {
	_id: 370,
	_object: 'Attribute',
	context: `${phoneContact.context}:comment`,
	definition: null,
	isComposite: false,
	alias: makeAlias(phoneContact.name, 'comment'),
	isPrimary: false,
	name: 'comment',
	plural: 'Comments',
	singular: 'Comment',
	entity: {
		_id: phoneContact._id,
		singular: 'Phone Contact',
		plural: 'Phone Contacts',
		_object: 'Entity',
		name: phoneContact.name,
	},
	stats: {},
	type: 'category',
	hasSource: true,
	userContext: baseUserContext,
	profile: {
		results: {
			maxLength: 200,
			minLength: 17,
		},
	},
};
export const phoneNumber: IdentityAttribute = {
	_id: 305,
	_object: 'Attribute',
	context: `${phoneContact.context}:phone-number`,
	definition: null,
	isComposite: false,
	alias: makeAlias(phoneContact.name, 'phone-number'),
	isPrimary: false,
	name: 'phone-number',
	plural: 'Phone Numbers',
	singular: 'Phone Number',
	entity: {
		singular: 'Phone Contact',
		plural: 'Phone Contacts',
		_id: phoneContact._id,
		_object: 'Entity',
		name: phoneContact.name,
	},
	stats: {},
	type: 'identity',
	hasSource: true,
	userContext: baseUserContext,
	profile: {
		results: {
			maxLength: 12,
			minLength: 12,
		},
	},
};

export const firstContactDate: EventAttribute = {
	_id: 314,
	_object: 'Attribute',
	context: `${phoneContact.context}:first-contact-date`,
	definition: null,
	alias: makeAlias(phoneContact.name, 'first-contact-date'),
	isPrimary: false,
	name: 'first-contact-date',
	plural: 'First Contact Dates',
	singular: 'First Contact Date',
	entity: {
		singular: 'Phone Contact',
		plural: 'Phone Contacts',
		_id: phoneContact._id,
		_object: 'Entity',
		name: phoneContact.name,
	},
	stats: {},
	type: 'event',
	subtype: 'ISODateString',
	isComposite: true,
	hasSource: true,
	userContext: baseUserContext,
};

export const contactStoreLat: LocationAttribute = {
	_id: 352,
	_object: 'Attribute',
	context: `${phoneContact.context}:contact-store-lat`,
	definition: 'Geographic latitude of contact',
	isPrimary: false,
	alias: makeAlias(phoneContact.name, 'contact-store-lat'),
	isComposite: false,
	name: 'contact-store-lat',
	plural: 'Contact Store Latitudes',
	singular: 'Contact Store Latitude',
	entity: {
		_id: phoneContact._id,
		singular: 'Phone Contact',
		plural: 'Phone Contacts',
		_object: 'Entity',
		name: phoneContact.name,
	},
	stats: {},
	type: 'location',
	hasSource: true,
	userContext: baseUserContext,
	subtype: 'latitude',
};

export const contactStoreLong: LocationAttribute = {
	subtype: 'longitude',
	_id: 362,
	_object: 'Attribute',
	context: `${phoneContact.context}:contact-store-long`,
	definition: 'Geographic longitude of contact',
	isPrimary: false,
	isComposite: false,
	name: 'contact-store-long',
	alias: makeAlias(phoneContact.name, 'contact-store-long'),
	plural: 'Contact Store Longitudes',
	singular: 'Contact Store Longitude',
	entity: {
		singular: 'Phone Contact',
		plural: 'Phone Contacts',
		_id: phoneContact._id,
		_object: 'Entity',
		name: phoneContact.name,
	},
	stats: {},
	type: 'location',
	hasSource: true,
	userContext: baseUserContext,
};

export const contactStoreLocation: LocationAttribute = {
	_id: 367,
	_object: 'Attribute',
	context: `${phoneContact.context}:contact-store-location`,
	definition: 'Lat and Long of contact store',
	isPrimary: false,
	name: 'contact-store-location',
	isComposite: true,
	alias: makeAlias(phoneContact.name, 'contact-store-location'),
	plural: 'Contact Store Locations',
	singular: 'Contact Store Location',
	entity: {
		singular: 'Phone Contact',
		plural: 'Phone Contacts',
		_id: phoneContact._id,
		_object: 'Entity',
		name: phoneContact.name,
	},
	stats: {},
	type: 'location',
	subtype: 'latlong',
	hasSource: true,
	userContext: baseUserContext,
};

export const callbackInterval: CategoryAttribute = {
	_id: 306,
	_object: 'Attribute',
	context: `${phoneContact.context}:callback-interval`,
	definition: null,
	isPrimary: false,
	isComposite: false,
	name: 'callback-interval',
	alias: makeAlias(phoneContact.name, 'callback-interval'),
	plural: 'Callback Intervals',
	singular: 'Callback Interval',
	hasSource: true,
	stats: {},
	type: 'category',
	entity: {
		_id: phoneContact._id,
		singular: 'Phone Contact',
		plural: 'Phone Contacts',
		_object: 'Entity',
		name: phoneContact.name,
	},
	userContext: baseUserContext,
	profile: {
		results: {
			maxLength: 7,
			minLength: 5,
		},
	},
};

export const id: IdentityAttribute = {
	_id: 372,
	_object: 'Attribute',
	context: `${phoneContact.context}:id`,
	isComposite: false,
	definition: null,
	isPrimary: true,
	name: 'id',
	plural: 'Ids',
	alias: makeAlias(phoneContact.name, 'id'),
	singular: 'Id',
	hasSource: true,
	stats: {},
	type: 'identity',
	entity: {
		singular: 'Phone Contact',
		plural: 'Phone Contacts',
		_id: phoneContact._id,
		_object: 'Entity',
		name: phoneContact.name,
	},
	userContext: baseUserContext,
	profile: {
		results: {
			max: 100000000000,
			min: 999999999000,
		},
	},
};

// phoneContact
export const timesContacted: QuantityAttribute = {
	_id: 373,
	_object: 'Attribute',
	context: `${phoneContact.context}:times-contacted`,
	definition: null,
	isPrimary: false,
	name: 'times-contacted',
	plural: 'Times Contacted',
	alias: makeAlias(phoneContact.name, 'times-contacted'),
	singular: 'Times Contacted',
	hasSource: true,
	isComposite: false,
	stats: {},
	type: 'quantity',
	entity: {
		_id: phoneContact._id,
		singular: 'Phone Contact',
		plural: 'Phone Contacts',
		_object: 'Entity',
		name: phoneContact.name,
	},
	userContext: baseUserContext,
	profile: {
		results: {
			min: 0,
			max: 69,
		},
	},
};

// phoneContact
export const purchaseCount: QuantityAttribute = {
	_id: 374,
	_object: 'Attribute',
	context: `${phoneContact.context}:purchase-count`,
	definition: null,
	isPrimary: false,
	name: 'purchase-count',
	plural: 'Purchase Counts',
	alias: makeAlias(phoneContact.name, 'purchase-count'),
	isComposite: false,
	singular: 'Purchase Count',
	hasSource: true,
	stats: {},
	type: 'quantity',
	entity: {
		singular: 'Phone Contact',
		plural: 'Phone Contacts',
		_id: phoneContact._id,
		_object: 'Entity',
		name: phoneContact.name,
	},
	userContext: baseUserContext,
	profile: {
		results: {
			min: 0,
			max: 5,
		},
	},
};

// hire
export const hireDate: BaseAttribute = {
	_id: 350,
	_object: 'Attribute',
	context: `${hire.context}:hire-date`,
	hasSource: true,
	alias: makeAlias(hire.name, 'hire-date'),
	definition: null,
	isComposite: true,
	entity: {
		singular: 'Hire',
		plural: 'Hires',
		_id: hire._id,
		_object: 'Entity',
		name: hire.name,
	},
	isPrimary: false,
	name: 'hire-date',
	plural: 'Hire Dates',
	singular: 'Hire Date',
	stats: {},
	type: 'event',
	subtype: 'ISODateString',
	userContext: baseUserContext,
};

export const role: BaseAttribute = {
	_id: 307,
	_object: 'Attribute',
	context: `${hire.context}:role`,
	definition: null,
	hasSource: true,
	alias: makeAlias(hire.name, 'role'),
	isPrimary: false,
	entity: {
		singular: 'Hire',
		plural: 'Hires',
		_id: hire._id,
		_object: 'Entity',
		name: hire.name,
	},
	name: 'role',
	plural: 'Roles',
	singular: 'Role',
	isComposite: false,
	stats: {},
	type: 'category',
	userContext: baseUserContext,
};

// retirement
export const retireDate: BaseAttribute = {
	_id: 308,
	alias: makeAlias(retirement.name, 'retire-date'),
	_object: 'Attribute',
	context: `${retirement.context}:retire-date`,
	definition: null,
	isPrimary: false,
	entity: {
		singular: 'Retirement',
		plural: 'Retirements',
		_id: retirement._id,
		_object: 'Entity',
		name: retirement.name,
	},
	name: 'retire-date',
	isComposite: true,
	subtype: 'ISODateString',
	plural: 'Retire Dates',
	singular: 'Retire Date',
	hasSource: true,
	stats: {},
	type: 'event',
	userContext: baseUserContext,
};

export const employeeAge: BaseAttribute = {
	_id: 309,
	_object: 'Attribute',
	context: `${retirement.context}:employee-age`,
	definition: null,
	hasSource: true,
	isPrimary: false,
	isComposite: false,
	alias: makeAlias(retirement.name, 'employee-age'),
	entity: {
		singular: 'Retirement',
		plural: 'Retirements',
		_id: retirement._id,
		_object: 'Entity',
		name: retirement.name,
	},
	name: 'employee-age',
	plural: 'Employee Ages',
	singular: 'Employee Age',
	stats: {},
	type: 'category',
	userContext: baseUserContext,
};

// DataSource files
export const liveDataSets: LiveDataSet[] = [
	{
		sourceName: 'davis-associates',
		catalogName: 'catalog-one',
		label: 'LSAD Codes',
		name: 'lsad-codes',
		internalSourceId: davisAssociates._id,
		_object: 'DataSet',
		tableSchema: {
			columns: [
				{
					name: 'associated_geographic_entities',
					description: null,
				},
				{
					name: 'lsad_code',
					description: null,
				},
				{
					name: 'lsad_description',
					description: null,
				},
			],
		},
	},
	{
		sourceName: 'davis-associates',
		catalogName: 'catalog-one',
		internalSourceId: davisAssociates._id,
		label: 'MTFCC Codes',
		name: 'mtfcc-feature-class-codes',
		_object: 'DataSet',
		tableSchema: {
			columns: [
				{
					name: 'areal',
					description: null,
				},
				{
					description: null,
					name: 'description',
				},
				{
					description: null,
					name: 'feature_class',
				},
				{
					description: null,
					name: 'feature_class_code',
				},
			],
		},
	},
	{
		sourceName: 'corpo-source',
		internalSourceId: 42,
		catalogName: 'catalog-two',
		label: 'FIPS codes',
		name: 'fips-codes-all',
		_object: 'DataSet',
		tableSchema: {
			columns: [
				{
					name: 'area_name',
					description: null,
				},
				{
					name: 'consolidated_city',
					description: null,
				},
				{
					name: 'county',
					description: null,
				},
				{
					name: 'county_subdivision',
					description: null,
				},
				{
					name: 'place',
					description: null,
				},
				{
					description: null,
					name: 'state',
				},
				{
					description: null,
					name: 'summary_level',
				},
				{
					description: null,
					name: 'summary_level_name',
				},
			],
		},
	},
	{
		sourceName: 'corpo-source',
		internalSourceId: 42,
		catalogName: 'catalog-two',
		label: 'MTFCC Codes',
		name: 'mtfcc-feature-class-codes',
		_object: 'DataSet',
		tableSchema: {
			columns: [
				{
					description: null,
					name: 'areal',
				},
				{
					description: null,
					name: 'description',
				},
				{
					description: null,
					name: 'feature_class',
				},
				{
					description: null,
					name: 'feature_class_code',
				},
			],
		},
	},
];

export const dataCatalogSummaries: DataCatalogSummaries = [
	{
		_object: 'DataCatalog',
		name: 'catalog-one',
		internalSourceId: 42,
		label: 'Catalog One',
		sourceType: 'bigquery',
		// datasets: [
		// 	{ name: 'lsad-codes' },
		// 	{ name: 'mtfcc-feature-class-codes' },
		// 	{ name: 'fips-codes-all' },
		// ],
		sourceName: 'davis-associates',
	},
	{
		_object: 'DataCatalog',
		name: 'catalog-two',
		label: 'Catalog Two',
		internalSourceId: 42,
		sourceType: 'bigquery',
		sourceName: 'corpo-source',
		// datasets: [{ name: 'mtfcc-feature-class-codes' }],
	},
];

export const dataSourceSummaries: DataSourceSummaries = [
	{
		_id: 1,
		_object: 'DataSource',
		name: 'davis-associates',
		sourceType: 'bigquery',
	},
	{
		_id: 4,
		_object: 'DataSource',
		name: 'corpo-source',
		sourceType: 's3',
	},
];
