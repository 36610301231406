import ClearFiltersButton from './ClearFiltersButton';
import ToggleCreationFormButton from './CreateButton';
import RecenterButton from './RecenterButton';
import ToggleRightDrawerButton from './ToggleRightDrawerButton';
import { FunctionComponent } from 'react';
import styled from 'styled-components';

const StyledControlsWrapper = styled.div`
	position: absolute;
	top: 107px;
	/* 32px is width of icon button at size "sm" */
	transform: translateY(-100%)
		${(p) => `translateX(-${p.theme.panelGutter + 38}px)`};
	display: flex;
	flex-direction: column;
	gap: ${(p) => p.theme.spacing(0.3)};
	justify-content: start;
	z-index: ${(p) => p.theme.zIndex.controlSurface};
`;

const ControlBar: FunctionComponent = () => {
	return (
		<StyledControlsWrapper>
			<ToggleRightDrawerButton />
			{/* <ToggleHUDButton /> */}
			<RecenterButton />
			<ClearFiltersButton />
			<ToggleCreationFormButton />
		</StyledControlsWrapper>
	);
};

export default ControlBar;
