import Modal from '../Modal';
import VisuallyHidden from '../VisuallyHidden';
import { NotificationLevel } from './notificationTypes';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

// Notifications should have an id for deduplicating
// accessibility elements in the event multiple Notifications
// are on screen at once.
interface Props {
	id: string;
	level: NotificationLevel;
	closeHandler: () => void;
	isOpen: boolean;
}

const NotificationContainer = styled.div<{ level: Props['level'] }>`
	background: ${(p) => p.theme.palette[p.level].main};
	color: ${(p) => p.theme.palette[p.level].contrastText};
	border-radius: ${(p) => p.theme.spacing(0.5)};
	display: flex;
	justify-content: space-between;
	padding: ${(p) => p.theme.spacing(2)};
	max-width: 30vw;
	margin: auto;
`;

const Notification: FunctionComponent<Props> = ({
	level,
	isOpen,
	closeHandler,
	children,
	id,
}) => {
	const labelString = `dismiss ${level} notification`;

	return (
		<Modal
			isOpen={isOpen}
			shouldCloseOnOverlayClick={false}
			onRequestClose={closeHandler}
			contentLabel={`Application ${level}`}
		>
			<NotificationContainer level={level}>
				{children}
				<VisuallyHidden>
					<label htmlFor={id}>{labelString}</label>
				</VisuallyHidden>
				<button id={id} onClick={closeHandler}>
					Close
				</button>
			</NotificationContainer>
		</Modal>
	);
};
export default Notification;
