import Button from 'common/Button';
import FlexContainer from 'common/FlexContainer';
import { FormBody, FormResults } from 'common/Form';
import Textbox from 'common/Textbox';
import Typography from 'common/Typography';
import theme from 'common/theme/theme';
import { useAuthenticateUserMutation } from 'features/api';
import useAuthStatus from 'features/authentication/hooks/useAuthStatus';
import React, { FunctionComponent, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Link, Location } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

interface CredentialsFormValues {
	username: string;
	password: string;
}

// const StyledLogo = styled.img`
// 	margin-top: ${(p) => p.theme.spacing(10)};
// `;

const StyledFormBody = styled.form`
	border-radius: ${(p) => p.theme.spacing(1)};
	background: ${(p) => p.theme.palette.darkerBaby};
	/* padding: ${(p) => p.theme.spacing(3, 3, 2)}; */
	max-width: 450px;
	min-width: 300px;
	width: 50%;
	margin: 0;
	justify-self: start;
	/* @media screen and (min-width: 1400px) {
		margin-right: 475px;
	} */
	margin-left: 8%;

	@media (max-width: 750px) {
		justify-self: center;
		margin: 0;
	}
`;

const StyledLogo = styled.img`
	/* height: 100%;
	max-height: 30vh; */
	justify-self: end;
	margin-right: 8%;
	max-width: 100%;
	max-height: 30vh;

	@media (max-width: 750px) {
		justify-self: center;
		margin: 0;
		max-height: 15vh;
	}
`;

const StyledMain = styled.div`
	background-color: ${(p) => p.theme.palette.darkBaby};
	width: 100%;
	height: 100vh;
`;

const StyledContainer = styled.div`
	display: grid;
	grid-gap: ${(p) => p.theme.spacing(4)};
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: min-content;
	grid-auto-columns: 1fr;
	align-items: center;
	margin-top: 30vh;
	margin-bottom: ${(p) => p.theme.spacing(4)};
	padding: 0 24px;
	/* transform: translateY(-50%); */

	@media (max-width: 750px) {
		grid-template-columns: 1fr;
		grid-template-rows: repeat(3, min-content);
		justify-items: center;
		align-items: start;
		margin-top: ${(p) => p.theme.navHeight}px;
	}
`;

const StyledCopyright = styled.p`
	grid-column: span 2;
	color: #3e4354;
	text-align: center;
	font-size: 12px;

	@media (max-width: 750px) {
		grid-column: span 1;
	}
`;

const CredentialsPage: FunctionComponent = () => {
	const { handleSubmit, register, formState } = useForm({
		defaultValues: {
			username: '',
			password: '',
		},
	});
	const isAuthorized = useAuthStatus();

	const location = useLocation() as {
		state?: { returnWhenAuthenticated?: Location };
		pathname: string;
	};

	const navigate = useNavigate();

	// const [dims, setDims] = useClientRect();

	// const { height } = dims;

	const [authenticate, mutationResults] = useAuthenticateUserMutation();

	// If 'returnWhenAuthenticated' is specified, it means the user arrived
	// at login form via redirect from a protected route.  If auth succeeds,
	// send them back to wherever they were trying to go before, and remove
	// login route from history to keep browser's 'back' functionality from
	// needlessly returning them to the auth page.  Otherwise, kick them back to
	// landing once authenticated.
	useEffect(() => {
		if (isAuthorized) {
			const previousLocation =
				location.state?.returnWhenAuthenticated?.pathname || null;

			const prevSearch =
				location.state?.returnWhenAuthenticated?.search || '';

			return previousLocation
				? navigate(previousLocation + prevSearch, { replace: true })
				: navigate('/');
		}
	}, [isAuthorized, navigate, location]);

	const onSubmit: SubmitHandler<CredentialsFormValues> = (credentials) => {
		authenticate({
			kind: location.pathname.match('login') ? 'login' : 'signup',
			credentials,
		});
	};

	return (
		<StyledMain>
			{/*<StyledLogo*/}
			{/*		style={{*/}
			{/*			position: 'relative',*/}
			{/*			left: '30%',*/}
			{/*			top: '15%',*/}
			{/*			width: '15%',*/}
			{/*			height: '15%'*/}
			{/*		}}*/}
			{/*		src='https://storage.googleapis.com/davis-associates.appspot.com/futuremodel-teaser.svg'*/}
			{/*		role="presentation"*/}
			{/*	/>*/}
			<StyledContainer>
				<StyledLogo
					src="https://storage.googleapis.com/davis-associates.appspot.com/fm-hero-figure.svg"
					role="presentation"
				/>
				<StyledFormBody
					onSubmit={handleSubmit(onSubmit)}
					aria-label="log in"
				>
					<FlexContainer
						flexDirection="column"
						style={{
							gap: '4px',
							padding: `${theme.spacing(3, 3, 1)}`,
						}}
					>
						<Textbox
							style={{
								height: '50px',
								width: '100%',
								marginBottom: '2px',
							}}
							labelText="Username"
							error={formState.errors.username}
							{...register('username', {
								required: {
									value: true,
									message: 'Username is required',
								},
							})}
						/>
						<Textbox
							error={formState.errors.password}
							style={{
								height: '50px',
								width: '100%',
								marginBottom: '3px',
							}}
							labelText="Password"
							type="password"
							{...register('password', {
								required: {
									value: true,
									message: 'Password is required',
								},
							})}
						/>
						<Button
							style={{
								color: theme.palette.primary.main,
								backgroundColor: theme.palette.darkBaby,
								borderColor: theme.palette.drapeGray,
								height: '50px',
								width: '100%',
								borderRadius: theme.spacing(1),
							}}
							type="submit"
							data-testid="submit-credentials"
						>
							Log In
						</Button>

						<Link
							to="/reset-password-request"
							style={{
								textDecoration: 'none',
								color: 'inherit',
								padding: '8px 0',
							}}
						>
							<Typography
								style={{
									fontSize: '.8rem',
									fontWeight: '500',
									color: theme.palette.cyan,
								}}
							>
								Forgot password?
							</Typography>
						</Link>
					</FlexContainer>
					<FlexContainer
						justifyContent="center"
						style={{
							padding: '16px',
							borderTop: `2px solid ${theme.palette.slickRick}`,
						}}
					>
						<Link
							to="/"
							style={{
								textDecoration: 'none',
								color: 'inherit',
								position: 'relative',
							}}
						>
							<Button
								style={{
									margin: 'auto',
									color: theme.palette.primary.main,
									backgroundColor: theme.palette.darkBaby,
									borderColor: theme.palette.drapeGray,
									height: '40px',
									width: '150px',
									borderRadius: theme.spacing(1),
								}}
								type="submit"
								data-testid="submit-credentials"
							>
								Request access
							</Button>
						</Link>
					</FlexContainer>
					<FormResults {...mutationResults} validationErrors={{}} />
				</StyledFormBody>
				<StyledCopyright>Futuremodel © 2022</StyledCopyright>
			</StyledContainer>
		</StyledMain>
	);
};

export default CredentialsPage;
