import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import {
	FontAwesomeIcon,
	FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { FunctionComponent } from 'react';
import styled from 'styled-components';

const StyledSuccessContainer = styled.div`
	display: flex;
	justify-content: space-around;
	color: ${(p) => p.theme.palette.success.main};
`;

const StyledIcon = styled(FontAwesomeIcon)`
	color: ${(p) => p.theme.palette.success.main};
`;

interface SuccessIconProps extends Omit<FontAwesomeIconProps, 'icon'> {}

const Success: FunctionComponent<SuccessIconProps> = ({
	children,
	...restProps
}) => {
	return (
		<StyledSuccessContainer data-testid="form-success">
			{children}
			<StyledIcon icon={faCheckCircle} {...restProps} />
		</StyledSuccessContainer>
	);
};

export default Success;
