import { faker } from '@faker-js/faker';

// Adapted from https://stackoverflow.com/questions/6878761/javascript-how-to-create-random-longitude-and-latitudes
export const genInRange = (
	from: number,
	to: number,
	fixAt?: number | undefined
) => +(Math.random() * (to - from) + from).toFixed(fixAt);

const genNorthAmericanLong = () => genInRange(-60, -130, 2);

const genNorthAmericanLat = () => genInRange(30, 50, 2);

export const genNorthAmericanLatLong = () => [
	genNorthAmericanLat(),
	genNorthAmericanLong(),
];

export const genPhoneNumber = () =>
	[0, 0, 0]
		.map(() => Math.round(genInRange(100, 999, 1)).toString())
		.join('-');

const makeIdGenerator = () => {
	const used = new Set();

	const getRandom = () =>
		Math.round(genInRange(100000000000, 999999999000, 1));

	return () => {
		let randomId = getRandom();

		while (used.has(randomId)) {
			randomId = getRandom();
		}

		used.add(randomId);

		return randomId;
	};
};

export const genUniqueId = makeIdGenerator();

/**Random number to represent the number of times an individual belonging
 * to the 'phone-contact' entity has already been contacted.
 */
export const genRandomTimesContacted = () => Math.round(Math.random() * 70);

export const genTimestamp = () =>
	faker.date.between('2010-01-01T00:00:00Z', '2020-01-01T00:00:00Z');

export const callbackIntervals = [
	'daily',
	'weekly',
	'monthly',
	'yearly',
	'never',
];

export const pickInterval = () =>
	callbackIntervals[Math.floor(Math.random() * callbackIntervals.length)];
