import useUserProfile from '../hooks/useUserProfile';
// import faker from '@faker-js/faker';
import Button from 'common/Button';
import FlexContainer from 'common/FlexContainer';
import Typography from 'common/Typography';
import theme from 'common/theme/theme';
import { useCompleteOnboardingMutation } from 'features/api';
import { extractQueryErrMessage } from 'features/api/helpers';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const StyledSlideButton = styled(Button)<{ side: 'left' | 'right' }>`
	border-radius: 0;
	padding: ${(p) => p.theme.spacing(1)};
	position: ${(p) => (p.side === 'right' ? 'static' : 'absolute')};
	bottom: ${(p) => p.theme.spacing(1)};
	left: ${(p) => (p.side === 'left' ? p.theme.spacing(1) : 'auto')};
	right: ${(p) => (p.side === 'right' ? p.theme.spacing(1) : 'auto')};
	min-width: ${(p) => p.theme.spacing(12)};

	&:hover {
		border: none;
		color: white;
		background-color: ${(p) => p.theme.palette.primary.main};
	}
`;

const StyledOnboardingContainer = styled.div`
	margin: auto;
	display: grid;
	max-width: 1200px;
	width: 80%;
	align-items: center;
	grid-template-rows: min-content min-content;
`;

export const OnboardingBase: FunctionComponent<{ images: string[] }> = ({
	images,
}) => {
	const { isOnboarded } = useUserProfile();

	const navigate = useNavigate();

	const nodeRef = useRef();

	const [currentSlide, setCurrentSlide] = useState(0);

	const isFinalSlide = currentSlide === images.length - 1;

	const [onboard, onboardResult] = useCompleteOnboardingMutation();

	// We only want to push an already-onboarded user to home IF their
	// onboarded status is a result of successfully completing onboarding for the
	// first time. Otherwise they may be here for informational purposes, and we want
	// to let them explore.
	useEffect(() => {
		if (isOnboarded && onboardResult.isSuccess) {
			navigate('/');
		}
	}, [isOnboarded, navigate, onboardResult.isSuccess]);

	const btnText = onboardResult.isSuccess
		? 'Success!'
		: onboardResult.isLoading
		? 'Preparing...'
		: isFinalSlide
		? isOnboarded
			? 'Home'
			: 'Get Started'
		: 'Next';

	return (
		<StyledOnboardingContainer>
			<div style={{ position: 'relative' }}>
				<StyledSlideButton
					side="left"
					onClick={() => {
						if (currentSlide > 0) {
							setCurrentSlide((p) => p - 1);
						}
					}}
				>
					Previous
				</StyledSlideButton>
				<FlexContainer
					flexDirection="column"
					style={{
						position: 'absolute',
						bottom: '8px',
						right: '8px',
						gap: '8px',
					}}
				>
					<div
						style={{
							backgroundColor: theme.palette.primary.main,
							color: 'white',
							padding: '8px',
							width: '100%',
							textAlign: 'center',
						}}
					>
						<Typography>
							{currentSlide + 1} of {images.length}
						</Typography>
					</div>
					<StyledSlideButton
						side="right"
						onClick={() => {
							if (!isFinalSlide) {
								return setCurrentSlide((p) => p + 1);
							}

							if (isOnboarded) {
								return navigate('/');
							}

							onboard(null);
						}}
					>
						{btnText}
					</StyledSlideButton>
				</FlexContainer>
				<img
					src={images[currentSlide]}
					ref={nodeRef as any}
					alt=""
					style={{ width: '100%' }}
					data-testid={`img-${currentSlide}`}
				/>
			</div>
			{onboardResult.error && (
				<Typography
					paragraph
					color="error"
					style={{ textAlign: 'center', fontWeight: 'bold' }}
				>
					{extractQueryErrMessage(onboardResult.error)}
				</Typography>
			)}
		</StyledOnboardingContainer>
	);
};

const imgs = Array(4)
	.fill(null)
	.map(
		(_, i) =>
			`https://storage.googleapis.com/davis-associates.appspot.com/onboarding/${
				i + 1
			}.png`
	);

const Onboarding: FunctionComponent = () => <OnboardingBase images={imgs} />;

export default Onboarding;
