import Heading from '../../../common/Heading';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import theme from 'common/theme/theme';

const StyledHeaderSection = styled.header`
	display: flex;
	justify-content: flex-start;
	gap: ${(p) => p.theme.spacing(1)};
	margin-bottom: ${(p) => p.theme.spacing(1)};
`;

const StyledGraphicSquare = styled.div`
	background: ${(p) => p.theme.palette.primary.main};
	width: 100px;
	height: 100px;
`;

interface ProfileHeaderProps {
	headingText: string;
	subheadText?: string;
}

const ProfileHeader: FunctionComponent<ProfileHeaderProps> = ({
	children,
	headingText,
	subheadText,
}) => {
	return (
		<StyledHeaderSection>
			<StyledGraphicSquare />
			<div>
				<Heading style={{color: theme.palette.darkBaby}} component="h1">{headingText}</Heading>
				{subheadText && <Heading style={{color: theme.palette.darkBaby}} component="h2">{subheadText}</Heading>}
				{children}
			</div>
		</StyledHeaderSection>
	);
};

export default ProfileHeader;
