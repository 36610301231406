import { genUniqueId } from './helpers';
import { createMockErr } from './mockHTTPErr';
import { davisAssociates, salesLeads, warehouseInventory } from './rawData';
import { faker } from '@faker-js/faker';
import { BaseUser } from 'features/authentication/types/userTypes';
import {
	GetAccountAdminsParams,
	GetAccountGovernorsParams,
	GetAccountMembersParams,
	GetDomainMembersParams,
	GetDomainStewardsParams,
} from 'features/userManagement/types/MemberManagementTypes';

const genMockUser = (): BaseUser =>
	({
		_id: genUniqueId(),
		firstName: faker.name.firstName(),
		lastName: faker.name.lastName(),
		memberSince: faker.date.past().toUTCString(),
		lastSeen: faker.date.past().toUTCString(),
		tosAccepted: true,
		_object: 'User',
		emailConfirmed: true,
		username: faker.internet.userName(),
		defaultAccount: davisAssociates.name,
		avatar: {
			thumb: {
				url: faker.image.avatar(),
			},
		},
	} as BaseUser);

const genMockAdmins = () => [1, 2, 3].map(() => genMockUser());

const genMockGovernors = () => [1, 2, 3].map(() => genMockUser());

const genMockStewards = () => [1, 2, 3].map(() => genMockUser());

const genMockMembers = () => [1, 2, 3].map(() => genMockUser());

const accountMemberDB = new Map<
	number,
	{ governors: BaseUser[]; admins: BaseUser[]; members: BaseUser[] }
>();

accountMemberDB.set(davisAssociates._id, {
	members: genMockMembers(),
	governors: genMockGovernors(),
	admins: genMockAdmins(),
});

const domainMemberDB = new Map<
	number,
	{ stewards: BaseUser[]; members: BaseUser[] }
>();

domainMemberDB.set(salesLeads._id, {
	stewards: genMockStewards(),
	members: genMockMembers(),
});

domainMemberDB.set(warehouseInventory._id, {
	stewards: genMockStewards(),
	members: genMockMembers(),
});

export const getAccountAdmins = ({ accountId }: GetAccountAdminsParams) => {
	const res = accountMemberDB.get(accountId);

	if (!res) {
		return createMockErr(
			404,
			'No member data found for account with that id'
		);
	}

	return res.admins;
};

export const getAccountGovernors = ({
	accountId,
}: GetAccountGovernorsParams) => {
	const res = accountMemberDB.get(accountId);

	if (!res) {
		return createMockErr(
			404,
			'No member data found for account with that id'
		);
	}

	return res.governors;
};

export const getAccountMembers = ({ accountId }: GetAccountMembersParams) => {
	const res = accountMemberDB.get(accountId);

	if (!res) {
		return createMockErr(
			404,
			'No member data found for account with that id'
		);
	}

	return res.members;
};

export const getDomainMembers = ({ domainId }: GetDomainMembersParams) => {
	const res = domainMemberDB.get(domainId);

	if (!res) {
		return createMockErr(
			404,
			'No member data found for domain with that id'
		);
	}

	return res.members;
};

export const getDomainStewards = ({ domainId }: GetDomainStewardsParams) => {
	const res = domainMemberDB.get(domainId);

	if (!res) {
		return createMockErr(
			404,
			'No member data found for domain with that id'
		);
	}

	return res.stewards;
};

/**NB: this just returns a bunch of mock users unrelated to any other mock data.
Its only use it to provide dummy UI for user management panel--there is no logical
relationship between the data returned here and data anywhere else in the application.*/
export const getAllUsers = () =>
	Array(10)
		.fill(null)
		.map(() => genMockUser());
