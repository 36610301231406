import GlobalStyles from '../common/theme/GlobalStyles';
import theme from '../common/theme/theme';
import HUDTracker from '../features/HUD/components/HUDTracker';
import { store } from './store';
import React, { FunctionComponent } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

const Providers: FunctionComponent = ({ children }) => {
	return (
		<ReduxProvider store={store}>
			<BrowserRouter>
				<HUDTracker>
					<ThemeProvider theme={theme}>
						<GlobalStyles />
						{children}
					</ThemeProvider>
				</HUDTracker>
			</BrowserRouter>
		</ReduxProvider>
	);
};

export default Providers;
