import { GraphAction, TwoDGraphState } from './actions';
import { initialState } from './pureReducer';
import reducer from './pureReducer';
import { createContext, Dispatch, FunctionComponent, useReducer } from 'react';

export const ForceGraphContext = createContext<
	[TwoDGraphState, Dispatch<GraphAction>]
>([initialState, () => null as any]);

interface GraphProviderProps {
	patchedState?: Partial<TwoDGraphState>;
	injectedDispatch?: Dispatch<GraphAction>;
}

// Provide an inlet for manipulating this state during tests
const GraphContextProvider: FunctionComponent<GraphProviderProps> = ({
	children,
	patchedState,
	injectedDispatch,
}) => {
	const reducerState = patchedState
		? { ...initialState, ...patchedState }
		: initialState;

	const [graphContext, graphDispatch] = useReducer(reducer, reducerState);

	const dispatch = injectedDispatch ? injectedDispatch : graphDispatch;

	return (
		<ForceGraphContext.Provider value={[graphContext, dispatch]}>
			{children}
		</ForceGraphContext.Provider>
	);
};

export default GraphContextProvider;
