import NotFound from './NotFound';
import RequireAuth from './RequireAuth';
import AppBase from 'app/AppBase';
import HUD from 'features/HUD';
import SourceBrowser from 'features/SourceBrowser';
import CredentialsPage from 'features/authentication/components/CredentialsPage';
import Onboarding from 'features/authentication/components/Onboarding';
import RegisterPage from 'features/authentication/components/RegisterPage';
import RequestPWResetPage from 'features/authentication/components/RequestPWResetPage';
import ResetPWPage from 'features/authentication/components/ResetPWPage';
import TOSPage from 'features/authentication/components/TOSPage';
import EntityViews from 'features/compositeViews/EntityViews';
import AccountGraph from 'features/forceGraphs/components/AccountGraph';
import DomainGraph from 'features/forceGraphs/components/DomainGraph';
import LandingPage from 'features/landing/LandingPage';
import MarketingPage from 'features/landing/MarketingPage';
import AccountProfile from 'features/profilePages/AccountProfile';
import UserProfile from 'features/profilePages/UserProfile';
import { FunctionComponent } from 'react';
import { Routes, Route } from 'react-router-dom';

const PageRouter: FunctionComponent = () => {
	return (
		<Routes>
			<Route path="/" element={<AppBase />}>
				<Route index element={<LandingPage />} />

				<Route
					path="complete-registration"
					element={<RegisterPage />}
				/>

				<Route path="marketing" element={<MarketingPage />} />

				<Route
					path="reset-password-request"
					element={<RequestPWResetPage />}
				/>

				<Route path="reset-password" element={<ResetPWPage />} />

				<Route path="login" element={<CredentialsPage />} />

				<Route
					path="terms-of-service"
					element={
						<RequireAuth to="/login">
							<TOSPage />
						</RequireAuth>
					}
				/>

				<Route
					path="/onboarding"
					element={
						<RequireAuth to="/login">
							<Onboarding />
						</RequireAuth>
					}
				/>

				<Route
					path=":account"
					element={
						<RequireAuth to="/login" TOSRequired>
							<HUD />
						</RequireAuth>
					}
				>
					<Route index element={<AccountGraph />} />

					<Route path="sources" element={<SourceBrowser />} />

					<Route path="profile" element={<AccountProfile />} />

					<Route path=":domain">
						<Route index element={<DomainGraph />} />
						<Route path=":entity">
							<Route index element={<EntityViews />} />
						</Route>
					</Route>
				</Route>

				<Route path="/user">
					<Route
						path="profile"
						element={
							<RequireAuth to="/login" TOSRequired>
								<UserProfile />
							</RequireAuth>
						}
					/>
				</Route>
			</Route>

			<Route path="/404" element={<NotFound />} />
		</Routes>
	);
};

export default PageRouter;
