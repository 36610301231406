import {
	HUDState,
	WindowResizePayload,
	WindowScrollPayload,
	HUDButtonDefinition,
	HUDButtonType,
	ModalType,
	HUDIndividual,
} from '../types/HUDTypes';
import { createSlice, nanoid, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { ViewMode } from 'features/navigation/types/navigationTypes';

export const initialState: HUDState = {
	activeIndividual: null,
	recenterGraphKey: nanoid(),
	viewportWidth: 0,
	viewportHeight: 0,
	scrollX: 0,
	scrollY: 0,
	HUDActive: true,
	rightDrawerOpen: false,
	HUDAvailable: true,
	availableViews: [],
	buttons: [],
	modalType: null,
};

const HUDSlice = createSlice({
	name: 'HUD',
	initialState,
	reducers: {
		viewsAvailable: (state, action: PayloadAction<ViewMode[]>) => ({
			...state,
			availableViews: action.payload,
		}),

		resize: (state, action: PayloadAction<WindowResizePayload>) => {
			return { ...state, ...action.payload };
		},

		HUDActive: (state, action: PayloadAction<boolean>) => {
			return { ...state, HUDActive: action.payload };
		},

		toggleHUDActive: (state) => {
			return { ...state, HUDActive: state.HUDActive ? false : true };
		},

		toggleRightDrawer: (state) => {
			return {
				...state,
				rightDrawerOpen: state.rightDrawerOpen ? false : true,
			};
		},

		HUDAvailable: (state, action: PayloadAction<boolean>) => {
			return { ...state, HUDAvailable: action.payload };
		},

		scroll: (state, action: PayloadAction<WindowScrollPayload>) => {
			return { ...state, ...action.payload };
		},

		setViewMode: (state, action: PayloadAction<ViewMode>) => {
			return { ...state, viewMode: action.payload };
		},

		recenterGraph: (state) => {
			return { ...state, recenterGraphKey: nanoid() };
		},

		addHUDButtons: (
			state,
			action: PayloadAction<HUDButtonDefinition[]>
		) => {
			// rigamarole is to overwrite existing definitions of the same type as those dispatched,
			// then combine pre-existing and incoming definitions that were not involved in the overwrites.
			const incoming: Map<HUDButtonType, HUDButtonDefinition> =
				action.payload.reduce((acc, nextBtn) => {
					acc.set(nextBtn.buttonType, nextBtn);
					return acc;
				}, new Map() as any);

			const next: HUDButtonDefinition[] = [];

			state.buttons.forEach((buttonDef) => {
				if (incoming.has(buttonDef.buttonType)) {
					next.push(
						incoming.get(
							buttonDef.buttonType
						) as HUDButtonDefinition
					);
					incoming.delete(buttonDef.buttonType);
					return;
				}

				next.push(buttonDef);
			});

			return { ...state, buttons: [...next, ...incoming.values()] };
		},

		removeHUDButtons: (state, action: PayloadAction<HUDButtonType[]>) => {
			const remainingButtons = state.buttons.filter(
				(button) => !action.payload.includes(button.buttonType)
			);

			return { ...state, buttons: remainingButtons };
		},

		setModalType: (state, action: PayloadAction<ModalType>) => {
			return { ...state, modalType: action.payload };
		},

		setActiveIndividual: (state, action: PayloadAction<HUDIndividual>) => ({
			...state,
			rightDrawerOpen: true,
			activeIndividual: action.payload,
		}),

		clearActiveIndividual: (state) => ({
			...state,
			rightDrawerOpen: false,
			activeIndividual: null,
		}),
	},
});

export const selectViewportDimensions = ({ HUD }: RootState) => ({
	viewportWidth: HUD.viewportWidth,
	viewportHeight: HUD.viewportHeight,
});

export const selectHUDState = ({ HUD }: RootState) => HUD.HUDActive;

export const selectHUDAvailable = ({ HUD }: RootState) => HUD.HUDAvailable;

export const selectAvailableViews = ({ HUD }: RootState) => HUD.availableViews;

export const selectHUDButtons = ({ HUD }: RootState) => HUD.buttons;

export const selectModalType = ({ HUD }: RootState) => HUD.modalType;

export const selectRecenterGraphKey = ({ HUD }: RootState) =>
	HUD.recenterGraphKey;

export const selectRightDrawerOpen = ({ HUD }: RootState) =>
	HUD.rightDrawerOpen;

export const selectActiveIndividual = ({ HUD }: RootState) =>
	HUD.activeIndividual;

export const {
	clearActiveIndividual,
	setActiveIndividual,
	viewsAvailable,
	toggleRightDrawer,
	resize,
	HUDActive,
	scroll,
	HUDAvailable,
	addHUDButtons,
	removeHUDButtons,
	toggleHUDActive,
	setModalType,
	recenterGraph,
} = HUDSlice.actions;

export default HUDSlice.reducer;
