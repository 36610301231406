import {
	GetSourceSummariesResponse,
	GetSourceSummariesParams,
	CreateSourceDataResponse,
	CreateSourceParams,
	GetLiveDataSetPreviewParams,
	GetLiveDataSetPreviewResponse,
	GetCatalogSummariesResponse,
	GetCatalogSummariesParams,
	GetCatalogDataSetsResponse,
	GetCatalogDataSetsParams,
	ImportCatalogToDomainResponse,
	ImportCatalogToDomainParams,
	ImportDatasetToDomainParams,
	ImportDatasetToDomainResponse,
	GenDomainFromCatalogParams,
	GenDomainFromCatalogResponse,
	GenDomainFromDatasetResponse,
	GenDomainFromDatasetParams,
} from '../SourceBrowser/types/dataTypes';
import { tokenReceived } from '../authentication/state/tokenSlice';
import {
	authenticate,
	logout,
	userRegistered,
} from '../authentication/state/userSlice';
import { TokenDataResponse } from '../authentication/types/tokenTypes';
import {
	AuthenticateUserParams,
	GetUserParams,
	GetUsersResponse,
	InviteUserParams,
	InviteUserResponse,
	RegisterUserParams,
	RegisterUserResponse,
	RequestPWResetParams,
	RequestPWResetResponse,
	ResetPasswordParams,
	ResetPasswordResponse,
	UpdateUserDataResponse,
	UpdateUserParams,
	UserDataResponse,
	userResponseToAuthenticatedUser,
} from '../authentication/types/userTypes';
import { applicationErr } from '../errorHandling/state/errorSlice';
import { toDispatchableErr } from '../errorHandling/types/errorTypes';
import {
	AggregateAttrActionParams,
	AggregateAttrActionResponse,
} from '../futuremodelActions/aggregateAttribute/aggregateAttributeTypes';
import {
	AppendAttrActionParams,
	AppendAttrActionResponse,
} from '../futuremodelActions/appendAttribute/appendAttributeTypes';
import {
	CreateAttrActionParams,
	CreateAttrActionResponse,
} from '../futuremodelActions/createAttribute/createAttributeTypes';
import {
	CreateConditionalActionParams,
	CreateConditionalActionResponse,
} from '../futuremodelActions/createConditional/createConditionalTypes';
import {
	CreateEventActionParams,
	CreateEventActionResponse,
} from '../futuremodelActions/createEvent/createEventTypes';
import {
	CreateLocationActionParams,
	CreateLocationActionResponse,
} from '../futuremodelActions/createLocation/createLocationTypes';
import {
	DeleteAttrActionParams,
	DeleteAttrActionResponse,
} from '../futuremodelActions/deleteAttribute/deleteAttributeTypes';
import {
	DescribeAttrActionParams,
	DescribeAttrActionResponse,
} from '../futuremodelActions/describeAttribute/describeAttributeTypes';
import {
	DescribeDomainParams,
	DescribeDomainResponse,
} from '../futuremodelActions/describeDomain/describeDomainTypes';
import {
	DescribeEntityParams,
	DescribeEntityResponse,
} from '../futuremodelActions/describeEntity/describeEntityTypes';
import {
	LoadAttrActionParams,
	LoadAttrActionResponse,
} from '../futuremodelActions/loadAttribute/loadAttributeTypes';
import {
	MeltEntityParams,
	MeltEntityResponse,
} from '../futuremodelActions/meltEntity/meltEntityTypes';
import {
	OperateAttrActionParams,
	OperateAttrActionResponse,
} from '../futuremodelActions/operateAttribute';
import {
	PersistEntityParams,
	PersistEntityResponse,
} from '../futuremodelActions/persistEntity/persistEntityTypes';
import {
	RelateAttrActionParams,
	RelateAttrActionResponse,
} from '../futuremodelActions/relateAttribute/relateAttributeTypes';
import {
	RestrictEntityActionParams,
	RestrictEntityActionResponse,
} from '../futuremodelActions/restrictEntity/restrictEntityTypes';
import { ReserveUserParams, ReserveUserResponse } from '../landing/TeaserPage';
import {
	AccountDataResponse,
	GetAccountParams,
	GetAccountGraphParams,
	AccountGraphDataResponse,
	AccountsDataResponse,
	GetAccountsParams,
} from '../ontology/types/accountTypes';
import {
	AttributeDataResponse,
	AttributesDataResponse,
	GetAttributeParams,
	GetAttributesParams,
	GetDomainAttrsParams,
	GetDomainAttrsResponse,
	GetAttrNeighborsParams,
	GetAttrNeighborsResponse,
} from '../ontology/types/attributeTypes';
import {
	BaseDomain,
	CreateDomainParams,
	DeleteDomainParams,
	DeleteDomainResponse,
	DomainGraphDataResponse,
	DomainsDataResponse,
	FollowDomainParams,
	FollowDomainResponse,
	GetDomainGraphParams,
	GetDomainParams,
	GetDomainResponse,
	GetDomainsParams,
	UnfollowDomainParams,
	UnfollowDomainResponse,
} from '../ontology/types/domainTypes';
import {
	CreateEntityDataResponse,
	CreateEntityParams,
	EntitiesDataResponse,
	EntityDataResponse,
	FollowEntityParams,
	FollowEntityResponse,
	GetEntitiesParams,
	GetIndividualsParams,
	GetIndividualsResponse,
	GetEntityParams,
	IdentifyEntityParams,
	IdentifyEntityResponse,
	ImportEntityParams,
	ImportEntityResponse,
	UnfollowEntityParams,
	UnfollowEntityResponse,
	UpdateEntityParams,
	UpdateEntityResponse,
} from '../ontology/types/entityTypes';
import {
	DeleteEntityParams,
	DeleteEntityResponse,
} from '../ontology/types/entityTypes';
import { BaseEntity } from '../ontology/types/entityTypes';
import {
	AcceptAnswerParams,
	AcceptAnswerResponse,
	CreateAnswerParams,
	CreateAnswerResponse,
	CreateQuestionParams,
	CreateQuestionResponse,
	GetQuestionsParams,
	GetQuestionsResponse,
} from '../questions/types/questionTypes';
import {
	CreateAccountAdminParams,
	CreateAccountAdminResponse,
	CreateAccountGovernorParams,
	CreateAccountGovernorResponse,
	CreateAccountMemberParams,
	CreateAccountMemberResponse,
	CreateDomainMemberParams,
	CreateDomainMemberResponse,
	CreateDomainStewardParams,
	CreateDomainStewardResponse,
	GetAccountAdminsParams,
	GetAccountAdminsResponse,
	GetAccountGovernorsParams,
	GetAccountGovernorsResponse,
	GetAccountMembersParams,
	GetAccountMembersResponse,
	GetDomainMembersParams,
	GetDomainMembersResponse,
	GetDomainStewardsParams,
	GetDomainStewardsResponse,
	RemoveUserRoleParams,
	RemoveUserRoleResponse,
} from '../userManagement/types/MemberManagementTypes';
import { baseQueryWithLogout } from './fetchBaseQuery';
import { memberMgmtInvalidator, parseQueryError } from './helpers';
import URLBuilders from './urlBuilder';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import { pipe } from 'common/utils/functionUtils';
import { isAppError } from 'common/utils/typeGuards';
import {
	GetAttributeLineageResponse,
	GetAttributeLineageParams,
} from 'features/ontology/types/lineageTypes';

export const futureModelApi = createApi({
	reducerPath: 'futureModelApi',
	tagTypes: [
		'user',
		'ontology',
		'entities',
		'attributes',
		'accounts',
		'domains',
		'sources',
		'questions',
		'accountAdmins',
		'accountGovernors',
		'accountMembers',
		'domainMembers',
		'domainStewards',
		'attrLineage',
	],
	baseQuery: baseQueryWithLogout,
	// Keep cached data until explicitly invalidated, for now.  This will likely
	// have to change in the future.
	keepUnusedDataFor: 60 * 60,
	// RTK Query uses shallow-equals to determine query argument equality,
	// so it is acceptable to pass objects as query args as long as they
	// are not deeply-nested.  We will still get the caching behavior
	// we expect.
	// https://redux-toolkit.js.org/rtk-query/usage/queries#query-hook-options
	endpoints: (build) => ({
		authenticateUser: build.mutation<
			TokenDataResponse,
			AuthenticateUserParams
		>({
			async queryFn(args, { dispatch }, extraOptions, baseQuery) {
				const encoded = btoa(
					`${args.credentials.username}:${args.credentials.password}`
				);
				const queryResult = await baseQuery({
					url: URLBuilders.authenticateUser.buildSubpath(),
					method: 'POST',
					headers: {
						Authorization: `Basic ${encoded}`,
					},
				});

				// automatically add token to redux state
				// if request succeeds
				if (queryResult.data) {
					const { token } = queryResult.data as TokenDataResponse;

					pipe(token, tokenReceived, dispatch);
				}

				return queryResult.error
					? { error: queryResult.error as FetchBaseQueryError }
					: { data: queryResult.data as TokenDataResponse };
			},

			invalidatesTags: ['user'],
		}),

		refreshToken: build.mutation<{ token: string }, any>({
			query: () => ({
				url: URLBuilders.refreshToken.buildSubpath(),
				method: 'POST',
			}),
		}),

		getUser: build.query<UserDataResponse, GetUserParams>({
			async queryFn(args, { dispatch }, extraOptions, baseQuery) {
				const queryResult = await baseQuery(
					URLBuilders.getUser.buildSubpath(args)
				);

				// query succeeded
				if (queryResult.data) {
					const maybeUser = userResponseToAuthenticatedUser(
						queryResult.data as UserDataResponse
					);

					// invalid dialect user role files came in from server
					if (isAppError(maybeUser)) {
						// Add err message to Redux state
						pipe(
							maybeUser,
							toDispatchableErr,
							applicationErr,
							dispatch
						);

						// log user out, since by this point in auth flow a token will have been set,
						// but is yielding invalid files
						pipe(logout(), dispatch);

						return {
							data: queryResult.data as UserDataResponse,
						};
					}

					// dispatch successfully-formatted user to Redux store
					pipe(maybeUser, authenticate, dispatch);

					// UI components need to know that query succeeded
					return {
						data: queryResult.data as UserDataResponse,
					};
				}

				// query failed, i.e. we don't have any user files from server.
				// dispatch parsed query error to Redux store
				else {
					pipe(
						queryResult.error as
							| FetchBaseQueryError
							| SerializedError,
						parseQueryError,
						toDispatchableErr,
						applicationErr,
						dispatch
					);

					return {
						error: queryResult.error as FetchBaseQueryError,
					};
				}
			},

			providesTags: ['user'],
		}),

		updateUser: build.mutation<UpdateUserDataResponse, UpdateUserParams>({
			query: (args) => ({
				url: URLBuilders.updateUser.buildSubpath(args),
				method: 'PUT',
				body: args.body,
			}),
			invalidatesTags: ['user'],
		}),

		reserveUser: build.mutation<ReserveUserResponse, ReserveUserParams>({
			query: (args) => ({
				url: URLBuilders.reserveUser.buildSubpath(),
				method: 'POST',
				body: args,
			}),
		}),

		signout: build.mutation<void, void>({
			query: () => ({
				url: URLBuilders.signout.buildSubpath(),
				method: 'DELETE',
			}),
			invalidatesTags: ['user'],
		}),

		getUsers: build.query<GetUsersResponse, void>({
			query: () => ({
				url: URLBuilders.getUsers.buildSubpath(),
			}),
		}),

		getAccounts: build.query<AccountsDataResponse, GetAccountsParams>({
			query: (args) => URLBuilders.getAccounts.buildSubpath(args),
			providesTags: ['ontology', 'accounts'],
		}),

		getAccountGraph: build.query<
			AccountGraphDataResponse,
			GetAccountGraphParams
		>({
			query: (args) => URLBuilders.getAccountGraph.buildSubpath(args),
			providesTags: ['ontology', 'domains'],
		}),

		getDomain: build.query<GetDomainResponse, GetDomainParams>({
			query: (args) => URLBuilders.getDomain.buildSubpath(args),
			providesTags: ['ontology', 'domains'],
		}),

		getDomains: build.query<DomainsDataResponse, GetDomainsParams>({
			query: (args) => URLBuilders.getDomains.buildSubpath(args),
			providesTags: ['ontology', 'domains'],
		}),

		createDomain: build.mutation<BaseDomain, CreateDomainParams>({
			query: (args) => ({
				url: URLBuilders.createDomain.buildSubpath(),
				method: 'POST',
				body: args,
			}),
			invalidatesTags: ['domains'],
		}),

		deleteDomain: build.mutation<DeleteDomainResponse, DeleteDomainParams>({
			query: (args) => ({
				url: URLBuilders.deleteDomain.buildSubpath(args),
				method: 'DELETE',
			}),
			invalidatesTags: ['domains'],
		}),

		getEntities: build.query<EntitiesDataResponse, GetEntitiesParams>({
			query: (args) => URLBuilders.getEntities.buildSubpath(args),
			providesTags: ['ontology', 'entities'],
		}),

		getEntity: build.query<BaseEntity, GetEntityParams>({
			query: (args) => URLBuilders.getEntity.buildSubpath(args),
			transformResponse: (res: EntityDataResponse) => res.response,
			providesTags: ['ontology', 'entities'],
		}),

		createEntity: build.mutation<
			CreateEntityDataResponse,
			CreateEntityParams
		>({
			query: (args) => ({
				url: URLBuilders.createEntity.buildSubpath(),
				method: 'POST',
				body: args,
			}),
			invalidatesTags: ['entities'],
		}),

		getQuestions: build.query<GetQuestionsResponse, GetQuestionsParams>({
			query: (args) => ({
				url: URLBuilders.getQuestions.buildSubpath(args),
			}),
			providesTags: (result, err, { objectId, objectType }) => [
				{ type: 'questions', id: `${objectType}-${objectId}` },
			],
		}),

		createQuestion: build.mutation<
			CreateQuestionResponse,
			CreateQuestionParams
		>({
			query: (args) => ({
				url: URLBuilders.createQuestion.buildSubpath(args),
				method: 'POST',
				body: args.body,
			}),
			invalidatesTags: (
				result,
				err,
				{ body: { objectId, objectType } }
			) => [{ type: 'questions', id: `${objectType}-${objectId}` }],
		}),

		createAnswer: build.mutation<CreateAnswerResponse, CreateAnswerParams>({
			query: (args) => ({
				url: URLBuilders.createAnswer.buildSubpath(args),
				method: 'POST',
				body: args.body,
			}),
			//    Answers are always fetched as nested data within a question object,
			// so when we create a new answer, we trigger fetching it by invalidating the
			// query for its parent question
			invalidatesTags: (
				result,
				err,
				{ parentObjectId, parentObjectType }
			) => [
				{
					type: 'questions',
					id: `${parentObjectType}-${parentObjectId}`,
				},
			],
		}),

		acceptAnswer: build.mutation<AcceptAnswerResponse, AcceptAnswerParams>({
			query: (args) => ({
				url: URLBuilders.acceptAnswer.buildSubpath(args),
				method: 'POST',
				body: args.body,
			}),
			//    Answers are always fetched as nested data within a question object,
			// so when we create a new answer, we trigger fetching it by invalidating the
			// query for its parent question
			invalidatesTags: (
				result,
				err,
				{ parentObjectId, parentObjectType }
			) => [
				{
					type: 'questions',
					id: `${parentObjectType}-${parentObjectId}`,
				},
			],
		}),

		getDomainGraph: build.query<
			DomainGraphDataResponse,
			GetDomainGraphParams
		>({
			query: (args) => URLBuilders.getDomainGraph.buildSubpath(args),
			providesTags: ['ontology', 'entities'],
		}),

		getAccount: build.query<AccountDataResponse, GetAccountParams>({
			query: (args) => URLBuilders.getAccount.buildSubpath(args),
			providesTags: ['ontology', 'accounts'],
		}),

		getAttribute: build.query<AttributeDataResponse, GetAttributeParams>({
			query: (args) => URLBuilders.getAttribute.buildSubpath(args),
			providesTags: ['ontology', 'attributes'],
		}),

		getAttributes: build.query<AttributesDataResponse, GetAttributesParams>(
			{
				query: (args) => URLBuilders.getAttributes.buildSubpath(args),
				providesTags: ['ontology', 'attributes'],
			}
		),

		//   TODO: need a way to invalidate this that make sense, probably associated with parent attribute
		getIndividuals: build.query<
			GetIndividualsResponse,
			GetIndividualsParams
		>({
			query: (args) => URLBuilders.getIndividuals.buildSubpath(args),
			providesTags: ['attributes'],
		}),

		getSourceSummaries: build.query<
			GetSourceSummariesResponse,
			GetSourceSummariesParams
		>({
			query: (args) => URLBuilders.getSourceSummaries.buildSubpath(args),
			providesTags: ['sources'],
		}),

		getCatalogSummaries: build.query<
			GetCatalogSummariesResponse,
			GetCatalogSummariesParams
		>({
			query: (args) => URLBuilders.getCatalogSummaries.buildSubpath(args),
			providesTags: ['sources'],
		}),

		getCatalogDataSets: build.query<
			GetCatalogDataSetsResponse,
			GetCatalogDataSetsParams
		>({
			query: (args) => URLBuilders.getCatalogDataSets.buildSubpath(args),
			providesTags: ['sources'],
		}),

		// TODO: eventually we will want to submit any file uploads separately for both client and
		// server performance.
		createSource: build.mutation<
			CreateSourceDataResponse,
			CreateSourceParams
		>({
			query: (args) => ({
				url: URLBuilders.createSource.buildSubpath(),
				method: 'POST',
				body: args,
			}),
			invalidatesTags: ['sources'],
		}),

		// genDomainFromSource: build.mutation<
		// 	GenDomainFromSourceResponse,
		// 	GenDomainFromSourceParams
		// >({
		// 	query: (args) => ({
		// 		url: URLBuilders.genDomainFromSource.buildSubpath(args),
		// 		method: 'POST',
		// 	}),

		// 	invalidatesTags: ['domains'],
		// }),

		importCatalogToDomain: build.mutation<
			ImportCatalogToDomainResponse,
			ImportCatalogToDomainParams
		>({
			query: (args) => ({
				url: URLBuilders.importCatalogToDomain.buildSubpath(args),
				method: 'POST',
			}),

			invalidatesTags: ['domains', 'entities'],
		}),

		importDatasetToDomain: build.mutation<
			ImportDatasetToDomainResponse,
			ImportDatasetToDomainParams
		>({
			query: (args) => ({
				url: URLBuilders.importDatasetToDomain.buildSubpath(args),
				method: 'POST',
			}),

			invalidatesTags: ['domains', 'entities'],
		}),

		genDomainFromCatalog: build.mutation<
			GenDomainFromCatalogResponse,
			GenDomainFromCatalogParams
		>({
			query: (args) => ({
				url: URLBuilders.genDomainFromCatalog.buildSubpath(args),
				method: 'POST',
			}),

			invalidatesTags: ['domains', 'entities'],
		}),

		genDomainFromDataset: build.mutation<
			GenDomainFromDatasetResponse,
			GenDomainFromDatasetParams
		>({
			query: (args) => ({
				url: URLBuilders.genDomainFromDataset.buildSubpath(args),
				method: 'POST',
			}),

			invalidatesTags: ['domains', 'entities'],
		}),

		getDataSetPreview: build.query<
			GetLiveDataSetPreviewResponse,
			GetLiveDataSetPreviewParams
		>({
			query: (args) =>
				URLBuilders.getLiveDataSetPreview.buildSubpath(args),
		}),

		followDomain: build.mutation<FollowDomainResponse, FollowDomainParams>({
			query: (args) => ({
				url: URLBuilders.followDomain.buildSubpath(args),
				method: 'POST',
			}),
			invalidatesTags: ['domains'],
		}),

		unfollowDomain: build.mutation<
			UnfollowDomainResponse,
			UnfollowDomainParams
		>({
			query: (args) => ({
				url: URLBuilders.unfollowDomain.buildSubpath(args),
				method: 'POST',
			}),
			invalidatesTags: ['domains'],
		}),

		followEntity: build.mutation<FollowEntityResponse, FollowEntityParams>({
			query: (args) => ({
				url: URLBuilders.followEntity.buildSubpath(args),
				method: 'POST',
			}),
			invalidatesTags: ['entities'],
		}),

		unfollowEntity: build.mutation<
			UnfollowEntityResponse,
			UnfollowEntityParams
		>({
			query: (args) => ({
				url: URLBuilders.unfollowEntity.buildSubpath(args),
				method: 'POST',
			}),
			invalidatesTags: ['entities'],
		}),

		getDomainAttributes: build.query<
			GetDomainAttrsResponse,
			GetDomainAttrsParams
		>({
			query: (args) => URLBuilders.getDomainAttributes.buildSubpath(args),
			providesTags: ['attributes'],
		}),

		identifyEntity: build.mutation<
			IdentifyEntityResponse,
			IdentifyEntityParams
		>({
			query: (args) => ({
				url: URLBuilders.identifyEntity.buildSubpath(args),
				method: 'POST',
				body: { attributeId: args.attributeId },
			}),
			invalidatesTags: ['entities', 'attributes'],
		}),

		relateAttrAction: build.mutation<
			RelateAttrActionResponse,
			RelateAttrActionParams
		>({
			query: (args) => ({
				url: URLBuilders.relateAttrAction.buildSubpath(args),
				method: 'POST',
				body: args.body,
			}),
			invalidatesTags: ['entities', 'attributes'],
		}),

		describeAttrAction: build.mutation<
			DescribeAttrActionResponse,
			DescribeAttrActionParams
		>({
			query: (args) => ({
				url: URLBuilders.describeAttrAction.buildSubpath(args),
				method: 'PUT',
				body: args.body,
			}),
			invalidatesTags: ['entities', 'attributes'],
		}),

		getAttrNeighbors: build.query<
			GetAttrNeighborsResponse,
			GetAttrNeighborsParams
		>({
			query: (args) => ({
				url: URLBuilders.getAttrNeighbors.buildSubpath(args),
			}),
			providesTags: ['entities', 'attributes'],
		}),

		appendAttrAction: build.mutation<
			AppendAttrActionResponse,
			AppendAttrActionParams
		>({
			query: (args) => ({
				url: URLBuilders.appendAttrAction.buildSubpath(args),
				method: 'POST',
				body: args.body,
			}),
			invalidatesTags: ['entities', 'attributes'],
		}),

		operateAttr: build.mutation<
			OperateAttrActionResponse,
			OperateAttrActionParams
		>({
			query: (args) => ({
				url: URLBuilders.operateAttrAction.buildSubpath(args),
				method: 'POST',
				body: args.body,
			}),
			invalidatesTags: ['entities', 'attributes'],
		}),

		// This endpoint handles both 'restrict entity' and 'specialize entity'
		// cases.  URL builder produces correct URL based on 'restrictionKind'
		// field in RestrictEntityActionParams.
		restrictEntity: build.mutation<
			RestrictEntityActionResponse,
			RestrictEntityActionParams
		>({
			query: (args) => ({
				url: URLBuilders.restrictEntityAction.buildSubpath(args),
				method: 'POST',
				body: args.body,
			}),
			invalidatesTags: ['entities', 'attributes'],
		}),

		loadAttribute: build.mutation<
			LoadAttrActionResponse,
			LoadAttrActionParams
		>({
			query: (args) => ({
				url: URLBuilders.loadAttribute.buildSubpath(args),
				method: 'POST',
				body: args.body,
			}),
			invalidatesTags: ['entities', 'attributes'],
		}),

		aggregateAttribute: build.mutation<
			AggregateAttrActionResponse,
			AggregateAttrActionParams
		>({
			query: (args) => ({
				url: URLBuilders.aggregateAttribute.buildSubpath(args),
				method: 'POST',
				body: args.body,
			}),
			invalidatesTags: ['entities', 'attributes'],
		}),

		deleteAttribute: build.mutation<
			DeleteAttrActionResponse,
			DeleteAttrActionParams
		>({
			query: (args) => ({
				url: URLBuilders.deleteAttribute.buildSubpath(args),
				method: 'DELETE',
			}),
			invalidatesTags: ['entities', 'attributes'],
		}),

		deleteEntity: build.mutation<DeleteEntityResponse, DeleteEntityParams>({
			query: (args) => ({
				url: URLBuilders.deleteEntity.buildSubpath(args as any),
				method: 'DELETE',
			}),
			invalidatesTags: ['entities', 'attributes'],
		}),

		updateEntity: build.mutation<UpdateEntityResponse, UpdateEntityParams>({
			query: (args) => ({
				url: URLBuilders.updateEntity.buildSubpath(args),
				method: 'PUT',
				body: args.body,
			}),
			invalidatesTags: ['entities', 'attributes'],
		}),

		describeDomain: build.mutation<
			DescribeDomainResponse,
			DescribeDomainParams
		>({
			query: (args) => ({
				url: URLBuilders.describeDomain.buildSubpath(args),
				method: 'PUT',
				body: args.body,
			}),
			invalidatesTags: ['domains'],
		}),

		createAttribute: build.mutation<
			CreateAttrActionResponse,
			CreateAttrActionParams
		>({
			query: (args) => ({
				url: URLBuilders.createAttribute.buildSubpath(),
				method: 'POST',
				body: args.body,
			}),
			invalidatesTags: ['entities', 'attributes'],
		}),

		meltEntity: build.mutation<MeltEntityResponse, MeltEntityParams>({
			query: (args) => ({
				url: URLBuilders.meltEntity.buildSubpath(args),
				method: 'POST',
				body: args.body,
			}),
			invalidatesTags: ['entities', 'attributes'],
		}),

		persistEntity: build.mutation<
			PersistEntityResponse,
			PersistEntityParams
		>({
			query: (args) => ({
				url: URLBuilders.persistEntity.buildSubpath(args),
				method: 'POST',
			}),
			invalidatesTags: ['entities', 'attributes'],
		}),

		importEntity: build.mutation<ImportEntityResponse, ImportEntityParams>({
			query: (args) => ({
				url: URLBuilders.importEntity.buildSubpath(args),
				method: 'POST',
			}),
			invalidatesTags: ['entities'],
		}),

		createConditional: build.mutation<
			CreateConditionalActionResponse,
			CreateConditionalActionParams
		>({
			query: (args) => ({
				url: URLBuilders.createConditional.buildSubpath(args),
				method: 'POST',
				body: args.body,
			}),
			invalidatesTags: ['entities', 'attributes'],
		}),

		requestPWReset: build.mutation<
			RequestPWResetResponse,
			RequestPWResetParams
		>({
			query: (args) => ({
				url: URLBuilders.requestPWReset.buildSubpath(),
				method: 'POST',
				body: args,
			}),
		}),

		resetPassword: build.mutation<
			ResetPasswordResponse,
			ResetPasswordParams
		>({
			query: (args) => ({
				url: URLBuilders.resetPassword.buildSubpath(),
				method: 'POST',
				body: args,
			}),
		}),

		completeRegistration: build.mutation<
			RegisterUserResponse,
			RegisterUserParams
		>({
			async queryFn(args, { dispatch }, extraOptions, baseQuery) {
				const queryResult = await baseQuery({
					url: URLBuilders.completeRegistration.buildSubpath(),
					method: 'POST',
					body: args,
				});

				// if registration succeeds, use response files to authenticate the user
				if (queryResult.data) {
					pipe(
						queryResult.data as RegisterUserResponse,
						userRegistered,
						dispatch
					);
				}

				return queryResult.error
					? { error: queryResult.error as FetchBaseQueryError }
					: { data: queryResult.data as RegisterUserResponse };
			},
		}),

		acceptTOS: build.mutation<{}, null>({
			query: () => ({
				url: URLBuilders.acceptTOS.buildSubpath(),
				method: 'POST',
			}),
			invalidatesTags: ['user'],
		}),

		completeOnboarding: build.mutation<{}, null>({
			query: () => ({
				url: URLBuilders.completeOnboarding.buildSubpath(),
				method: 'POST',
			}),
			invalidatesTags: ['user'],
		}),

		inviteUser: build.mutation<InviteUserResponse, InviteUserParams>({
			query: (args) => ({
				url: URLBuilders.inviteUser.buildSubpath(),
				method: 'POST',
				body: args,
			}),
			invalidatesTags: ['user'],
		}),

		describeEntity: build.mutation<
			DescribeEntityResponse,
			DescribeEntityParams
		>({
			query: (args) => ({
				url: URLBuilders.describeEntity.buildSubpath(args),
				method: 'PUT',
				// TODO: should have consistency in what gets passed to 'body' if one is needed
				body: args.body,
			}),
			invalidatesTags: ['entities'],
		}),

		getAccountAdmins: build.query<
			GetAccountAdminsResponse,
			GetAccountAdminsParams
		>({
			query: (args) => ({
				url: URLBuilders.getAccountAdmins.buildSubpath(args),
			}),
			providesTags: ['accountAdmins'],
		}),

		createAccountAdmin: build.mutation<
			CreateAccountAdminResponse,
			CreateAccountAdminParams
		>({
			query: (args) => ({
				url: URLBuilders.createAccountAdmin.buildSubpath(args),
				body: args.body,
				method: 'POST',
			}),
			invalidatesTags: ['accountAdmins'],
		}),

		getAccountGovernors: build.query<
			GetAccountGovernorsResponse,
			GetAccountGovernorsParams
		>({
			query: (args) => ({
				url: URLBuilders.getAccountGovernors.buildSubpath(args),
			}),
			providesTags: ['accountGovernors'],
		}),

		createAccountGovernor: build.mutation<
			CreateAccountGovernorResponse,
			CreateAccountGovernorParams
		>({
			query: (args) => ({
				url: URLBuilders.createAccountGovernor.buildSubpath(args),
				body: args.body,
				method: 'POST',
			}),
			invalidatesTags: ['accountGovernors'],
		}),

		getAccountMembers: build.query<
			GetAccountMembersResponse,
			GetAccountMembersParams
		>({
			query: (args) => ({
				url: URLBuilders.getAccountMembers.buildSubpath(args),
			}),
			providesTags: ['accountMembers'],
		}),

		createAccountMember: build.mutation<
			CreateAccountMemberResponse,
			CreateAccountMemberParams
		>({
			query: (args) => ({
				url: URLBuilders.createAccountMember.buildSubpath(args),
				body: args.body,
				method: 'POST',
			}),
			invalidatesTags: ['accountMembers'],
		}),

		getDomainMembers: build.query<
			GetDomainMembersResponse,
			GetDomainMembersParams
		>({
			query: (args) => ({
				url: URLBuilders.getDomainMembers.buildSubpath(args),
			}),
			providesTags: ['domainMembers'],
		}),

		createDomainMember: build.mutation<
			CreateDomainMemberResponse,
			CreateDomainMemberParams
		>({
			query: (args) => ({
				url: URLBuilders.createDomainMember.buildSubpath(args),
				body: args.body,
				method: 'POST',
			}),
			invalidatesTags: ['domainMembers'],
		}),

		getDomainStewards: build.query<
			GetDomainStewardsResponse,
			GetDomainStewardsParams
		>({
			query: (args) => ({
				url: URLBuilders.getDomainStewards.buildSubpath(args),
			}),
			providesTags: ['domainStewards'],
		}),

		createDomainSteward: build.mutation<
			CreateDomainStewardResponse,
			CreateDomainStewardParams
		>({
			query: (args) => ({
				url: URLBuilders.createDomainSteward.buildSubpath(args),
				body: args.body,
				method: 'POST',
			}),
			invalidatesTags: ['domainStewards'],
		}),

		removeUserRole: build.mutation<
			RemoveUserRoleResponse,
			RemoveUserRoleParams
		>({
			query: (args) => ({
				// TODO: figure out this type signature
				// @ts-ignore
				url: URLBuilders.removeUserRole.buildSubpath(args),
				method: 'DELETE',
			}),
			invalidatesTags: (res, err, { role, parentObjectType }) => [
				memberMgmtInvalidator(parentObjectType, role),
			],
		}),

		createLocation: build.mutation<
			CreateLocationActionResponse,
			CreateLocationActionParams
		>({
			query: (args) => ({
				url: URLBuilders.createLocation.buildSubpath(args),
				method: 'POST',
				body: args.body,
			}),
			invalidatesTags: ['attributes'],
		}),

		createEvent: build.mutation<
			CreateEventActionResponse,
			CreateEventActionParams
		>({
			query: (args) => ({
				url: URLBuilders.createEvent.buildSubpath(args),
				method: 'POST',
				body: args.body,
			}),
			invalidatesTags: ['attributes'],
		}),

		getAttributeLineage: build.query<
			GetAttributeLineageResponse,
			GetAttributeLineageParams
		>({
			query: (args) => ({
				url: URLBuilders.getAttributeLineage.buildSubpath(args),
			}),
			providesTags: ['attrLineage'],
		}),
	}),
});

export const {
	useGetUsersQuery,
	useGetAttributeLineageQuery,
	useCreateEventMutation,
	useCreateLocationMutation,
	useRemoveUserRoleMutation,
	useCreateAccountAdminMutation,
	useCreateAccountGovernorMutation,
	useCreateDomainStewardMutation,
	useCreateDomainMemberMutation,
	useGetDomainMembersQuery,
	useGetDomainStewardsQuery,
	useGetAccountMembersQuery,
	useGetAccountGovernorsQuery,
	useGetAccountAdminsQuery,
	useDescribeEntityMutation,
	useCreateAnswerMutation,
	useInviteUserMutation,
	useAcceptTOSMutation,
	useCompleteRegistrationMutation,
	useImportEntityMutation,
	useAuthenticateUserMutation,
	useGetUserQuery,
	useReserveUserMutation,
	useUpdateUserMutation,
	useSignoutMutation,
	useGetEntitiesQuery,
	useGetEntityQuery,
	useCreateEntityMutation,
	useGetAccountsQuery,
	useGetAccountGraphQuery,
	useGetDomainsQuery,
	useGetDomainGraphQuery,
	useCreateDomainMutation,
	useGetAccountQuery,
	useGetAttributeQuery,
	useGetAttributesQuery,
	useGetSourceSummariesQuery,
	useGetCatalogDataSetsQuery,
	useGetCatalogSummariesQuery,
	useCreateSourceMutation,
	useGetDataSetPreviewQuery,
	useImportCatalogToDomainMutation,
	useImportDatasetToDomainMutation,
	useGenDomainFromCatalogMutation,
	useGenDomainFromDatasetMutation,
	useFollowDomainMutation,
	useUnfollowDomainMutation,
	useFollowEntityMutation,
	useUnfollowEntityMutation,
	useIdentifyEntityMutation,
	useGetDomainAttributesQuery,
	useRelateAttrActionMutation,
	useAppendAttrActionMutation,
	useDescribeAttrActionMutation,
	useGetAttrNeighborsQuery,
	useOperateAttrMutation,
	useRestrictEntityMutation,
	useLoadAttributeMutation,
	useAggregateAttributeMutation,
	useDeleteAttributeMutation,
	useDeleteEntityMutation,
	useUpdateEntityMutation,
	useCreateAttributeMutation,
	useMeltEntityMutation,
	usePersistEntityMutation,
	useGetDomainQuery,
	useDeleteDomainMutation,
	useCreateConditionalMutation,
	useDescribeDomainMutation,
	useRequestPWResetMutation,
	useResetPasswordMutation,
	useGetIndividualsQuery,
	useCreateQuestionMutation,
	useGetQuestionsQuery,
	useAcceptAnswerMutation,
	useCreateAccountMemberMutation,
	useCompleteOnboardingMutation,
} = futureModelApi;
