import { keyExists, Tagged } from '../../common/utils/typeUtils';

export const MOCKHTTPERRTAG = '__mockHTTPError__';

export interface MockHTTPErr extends Tagged {
	__tag: typeof MOCKHTTPERRTAG;
	status: number;
	message?: string;
}

export const createMockErr = (
	status: number,
	message?: string
): MockHTTPErr => ({
	__tag: MOCKHTTPERRTAG,
	status,
	message,
});

export const isMockHTTPErr = (u: unknown): u is MockHTTPErr =>
	keyExists(u, '__tag') && u.__tag === MOCKHTTPERRTAG;
