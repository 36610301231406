import { defaultGraphMargins } from '../CONSTANTS';
import { asClass, nanoClassId, prefixClassName } from '../helpers';
import { SVGGraphProps } from '../types';
import environment from 'common/environment';
import * as d3 from 'd3';
import { FunctionComponent, useLayoutEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

const StyledRect = styled.rect`
	fill: ${(p) => p.theme.palette.primary.main};
`;

interface HistogrampProps extends SVGGraphProps {
	binCount?: number;
	facts: number[];
	xAxis?: boolean;
	yAxis?: boolean;
	onBrushEnd?: (v: [number, number]) => void;
}

export const BRUSH_OVERLAY_TEST_ID = 'histo-brush-overlay';
export const HISTO_BAR_TEST_ID = 'histo-bar';
export const HISTO_BAR_CLASSNAME = 'histo-bar';
export const HISTO_SVG_TEST_ID = 'histo-svg';
export const HISTO_SVG_CLASSNAME = 'histo-svg';
export const HISTO_BRUSH_CLASSNAME = 'histo-brush-overlay';
export const HISTO_X_CLASSNAME = 'histo-x-axis';
export const HISTO_Y_CLASSNAME = 'histo-y-axis';

const Histogram: FunctionComponent<HistogrampProps> = ({
	binCount = 30,
	width,
	height,
	facts,
	onBrushEnd,
	xAxis = true,
	yAxis = true,
	...margins
}) => {
	//  create a unique id that will live as long as this component is mounted.
	// this allows us to use d3 class-based selectors without worrying about selection
	// conflicts if this component is mounted in several places.
	const prefixClass = useMemo(() => {
		const componentId = nanoClassId();
		return prefixClassName(componentId);
	}, []);

	const classNames = useMemo(
		() => ({
			xAxis: prefixClass(HISTO_X_CLASSNAME),
			yAxis: prefixClass(HISTO_Y_CLASSNAME),
			barGroup: prefixClass(HISTO_BAR_CLASSNAME),
			svg: prefixClass(HISTO_SVG_CLASSNAME),
			brush: prefixClass(HISTO_BRUSH_CLASSNAME),
		}),
		[prefixClass]
	);

	const brushEnd = useMemo(() => onBrushEnd ?? (() => {}), [onBrushEnd]);

	const { top, bottom, left, right } = { ...defaultGraphMargins, ...margins };

	const yMargin = top + bottom;
	const xMargin = left + right;

	const [svgRef, setSVGRef] = useState<SVGSVGElement | null>(null);

	const brush = useMemo(() => {
		const b = d3.brushX();
		if (width && height) {
			b.extent([
				[left, top],
				[width - right, height - bottom],
			]);
		}
		return b;
	}, [width, height, bottom, left, right, top]);

	const { xScale, yScale, drawHeight, bins, thresholds } = useMemo(() => {
		if (facts.length === 0) {
			return {
				xScale: null,
				yScale: null,
				bins: [],
				drawWidth: 0,
				drawHeight: 0,
				thresholds: [],
			};
		}
		const drawWidth = width > 0 ? width - xMargin : 0;

		const drawHeight = height > 0 ? height - yMargin : 0;

		const extent = d3.extent(facts) as number[];

		const xScale = d3.scaleLinear().range([0, drawWidth]).domain(extent);

		const posToValue = (v: number) => xScale.invert(v - left);

		const valueToPos = (v: number) => xScale(v) + left;

		const [min, max] = extent;

		const thresholds = d3
			.range(binCount)
			.map((t) => min + (t / binCount) * (max - min));

		const bins = d3.bin().thresholds(thresholds)(facts);

		const yScale = d3
			.scaleLinear()
			.range([drawHeight, 0])
			.domain([0, d3.max(bins, (b) => b.length) as number])
			.nice();

		if (onBrushEnd) {
			brush.on('end', ({ selection }) => {
				if (selection) {
					brushEnd(selection.map(posToValue));
				}
			});

			brush.on('brush', (e) => {
				if (!e.sourceEvent) {
					return;
				}

				const [low, high] = e.selection.map(posToValue);

				const bottomBin = bins.find((b) => (b.x1 as number) > low) as {
					x0: number;
					x1: number;
				};
				const topBin = bins.find((b) => (b.x1 as number) > high) as {
					x0: number;
					x1: number;
				};

				(d3.select(asClass(classNames.brush)) as any).call(brush.move, [
					valueToPos(bottomBin.x0),
					valueToPos(topBin.x1),
				]);
			});
		}

		return { xScale, yScale, bins, drawHeight, drawWidth, thresholds };
	}, [
		facts,
		binCount,
		width,
		height,
		xMargin,
		yMargin,
		brush,
		brushEnd,
		classNames.brush,
		left,
		onBrushEnd,
	]);

	useLayoutEffect(() => {
		if (xScale && yScale) {
			if (yAxis) {
				(d3.select(asClass(classNames.yAxis)) as any)
					.attr('transform', `translate(${left}, ${top})`)
					.call(d3.axisLeft(yScale).tickSize(0).tickPadding(6));
			}

			if (xAxis) {
				(d3.select(asClass(classNames.xAxis)) as any)
					.attr(
						'transform',
						`translate(${left}, ${drawHeight + top})`
					)
					.call(
						d3
							.axisBottom(xScale)
							.tickValues(thresholds)
							.tickFormat(d3.format(',.2~f'))
							.tickSize(0)
							.tickPadding(6)
					);
			}

			//    SHENANIGANS: d3 tries to use SVG transition properties that aren't implemented in JSDOM.
			// If brush gets initialized in Jest tests it throws.
			if (environment.NODE_ENV !== 'test' && onBrushEnd) {
				(d3.select(asClass(classNames.brush)) as any)
					.call(brush)
					.call(brush.clear);
			}
		}
	}, [
		onBrushEnd,
		xScale,
		yScale,
		svgRef,
		drawHeight,
		left,
		top,
		brush,
		classNames,
		xAxis,
		yAxis,
		thresholds,
	]);

	return (
		<svg
			width={width}
			height={height}
			ref={setSVGRef}
			data-testid={HISTO_SVG_TEST_ID}
			className={classNames.svg}
		>
			{xScale &&
				yScale &&
				bins.map((b) => {
					const { x1, x0 } = b as { x1: number; x0: number };

					const barHeightCalc = drawHeight - yScale(b.length);
					const barHeight = barHeightCalc > 0 ? barHeightCalc : 0;

					const barWidthCalc = xScale(x1) - xScale(x0);
					const barWidth = barWidthCalc > 2 ? barWidthCalc - 2 : 0;

					return (
						<g
							key={`bar-${b.x0}`}
							transform={`translate(${xScale(x0) + left + 1}, ${
								yScale(b.length) + top
							})`}
							className={prefixClass(HISTO_BAR_CLASSNAME)}
						>
							<StyledRect
								width={barWidth}
								height={barHeight}
								data-testid={HISTO_BAR_TEST_ID}
							/>
						</g>
					);
				})}
			<g className={classNames.xAxis} />
			<g className={classNames.yAxis} />
			{onBrushEnd && (
				<g
					className={classNames.brush}
					data-testid={BRUSH_OVERLAY_TEST_ID}
				/>
			)}
		</svg>
	);
};

export default Histogram;
