import {
	ListItem,
	ListItemContent,
	ListItemProps,
} from '../List';
import { forwardRef } from 'react';
import styled from 'styled-components';

const StyledListItem = styled(ListItem)`
	font-weight: ${(p) => p.theme.typography.fontWeightBold};
	padding-left: 25px;
`;

export const ListItemAvatar = styled.div`
	flex: 0 0 5%;
`;

interface PopoverListItemProps extends ListItemProps {
	// icon: IconDefinition;
	textContent: string;
}

const StyledSquare = styled.div`
	width: ${(p) => p.theme.spacing(1)};
	height: ${(p) => p.theme.spacing(1)};
	background-color: ${(p) => p.theme.palette.primary.main};
`;

const PopoverListItem = forwardRef<HTMLElement, PopoverListItemProps>(
	({ textContent, ...props }, ref) => {
		return (
			<StyledListItem highlightOnHover={true} {...props} ref={ref as any}>
				<ListItemAvatar>
					<StyledSquare />
				</ListItemAvatar>
				<ListItemContent>
					{textContent}
				</ListItemContent>
			</StyledListItem>
		);
	}
);

export default PopoverListItem;
