import { useAppDispatch } from '../../../app/hooks';
import { pipe } from '../../../common/utils/functionUtils';
import {
	makeWindowCallback,
	updateScrollPosition,
	updateViewportDimensions,
} from '../helpers';
import { HUDAvailable } from '../state/HUDSlice';
import { FunctionComponent, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const HUDTracker: FunctionComponent = ({ children }) => {
	const appDispatch = useAppDispatch();

	const location = useLocation();

	const hudIsAvailable = !!location.pathname.match(/^\/hud\//);

	// Update redux store with info regarding whether HUD should be
	// available for the given page.  Dispatch only if it has changed.
	useEffect(() => {
		pipe(hudIsAvailable, HUDAvailable, appDispatch);
	}, [hudIsAvailable, appDispatch]);

	// Update Redux store window dimensions on load
	useEffect(() => {
		window.addEventListener('load', updateViewportDimensions(appDispatch));

		return () =>
			window.removeEventListener(
				'load',
				updateViewportDimensions(appDispatch)
			);
	});

	// Update Redux store window dimensions on window resize
	useEffect(() => {
		const resizeCallback = makeWindowCallback(
			updateViewportDimensions(appDispatch),
			200
		);

		window.addEventListener('resize', resizeCallback);

		return () => window.removeEventListener('resize', resizeCallback);
	}, [appDispatch]);

	// Update Redux store scroll position on scroll
	useEffect(() => {
		const scrollCallback = makeWindowCallback(
			updateScrollPosition(appDispatch),
			100
		);

		window.addEventListener('scroll', scrollCallback);

		return () => window.removeEventListener('scroll', scrollCallback);
	}, [appDispatch]);

	return <>{children}</>;
};

export default HUDTracker;
