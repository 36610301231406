import { defaultGraphMargins } from '../CONSTANTS';
import { asClass, nanoClassId, prefixClassName } from '../helpers';
import { DrawnPoint, PointDrawFn, SVGGraphProps } from '../types';
import { drawDefaultCard, drawDefaultLine } from './helpers';
import {
	EventLineDrawFn,
	Lines,
	EventLineArguments,
	EventCardArgs,
	EventCardDrawFn,
} from './types';
import { nanoid } from '@reduxjs/toolkit';
import * as d3 from 'd3';
import {
	FunctionComponent,
	ReactElement,
	useLayoutEffect,
	useMemo,
	useState,
} from 'react';

export const BRUSH_OVERLAY_TEST_ID = 'event-line-brush-overlay';
export const EVENT_LINE_BRUSH_CLASSNAME = 'event-line-brush-overlay';
export const EVENT_LINE_SVG_TEST_ID = 'event-line-svg';
export const EVENT_LINE_SVG_CLASSNAME = 'event-line-svg';
export const EVENT_LINE_X_CLASSNAME = 'event-line-x-axis';
export const EVENT_LINE_Y_CLASSNAME = 'event-line-y-axis';
export const EVENT_LINE_LINE_CLASSNAME = 'event-line-line';
export const EVENT_LINE_POINT_CLASSNAME = 'event-line-point';
export const EVENT_LINE_HOVER_CARD_CLASSNAME = 'event-line-hover-card';

interface EventLineChartProps extends SVGGraphProps {
	lines: Lines;
	drawPoint?: PointDrawFn<Date, number, any>;
	drawLine?: EventLineDrawFn;
	drawCard?: EventCardDrawFn;
}

const EventLineChart: FunctionComponent<EventLineChartProps> = ({
	width,
	height,
	lines,
	drawLine,
	drawCard,
	xAxis = true,
	yAxis = true,
	...margins
}) => {
	// TODO: move this into a hook.
	const prefixClass = useMemo(() => {
		const componentId = nanoClassId();
		return prefixClassName(componentId);
	}, []);

	const classNames = useMemo(
		() => ({
			xAxis: prefixClass(EVENT_LINE_X_CLASSNAME),
			yAxis: prefixClass(EVENT_LINE_Y_CLASSNAME),
			svg: prefixClass(EVENT_LINE_SVG_CLASSNAME),
			line: prefixClass(EVENT_LINE_LINE_CLASSNAME),
			point: prefixClass(EVENT_LINE_POINT_CLASSNAME),
			card: prefixClass(EVENT_LINE_HOVER_CARD_CLASSNAME),
		}),
		[prefixClass]
	);

	// const drawPointFn = drawPoint ?? drawDefaultPoint;

	const drawLineFn = drawLine ?? drawDefaultLine;

	const drawCardFn = drawCard ?? drawDefaultCard;

	const [cardArgs, setCardArgs] = useState<EventCardArgs>({
		x: 0,
		y: 0,
		xValue: '',
		yValue: '',
		visible: false,
		drawHeight: 0,
		drawWidth: 0,
	});

	const { top, bottom, left, right } = { ...defaultGraphMargins, ...margins };

	const yMargin = top + bottom;

	const xMargin = left + right;

	const { xScale, yScale, drawHeight, lineDefs, drawWidth } = useMemo(() => {
		if (lines.length === 0) {
			return {
				xScale: null,
				yScale: null,
				drawWidth: 0,
				drawHeight: 0,
				lineDefs: [] as EventLineArguments[],
			};
		}

		const drawWidth = width > 0 ? width - xMargin : 0;

		const drawHeight = height > 0 ? height - yMargin : 0;

		const xMax = d3.max(lines, (line) => line.xMax) as Date;
		const xMin = d3.min(lines, (line) => line.xMin) as Date;
		const yMax = d3.max(lines, (line) => line.yMax) as number;

		const xScale = d3
			.scaleUtc()
			.range([0, drawWidth])
			.domain([xMin, xMax])
			.nice();

		const yScale = d3
			.scaleLinear()
			.range([drawHeight, 0])
			.domain([0, yMax])
			.nice();

		//   NB: we trade performance (adding two more iterations) here for the convenience of having draw coordinates
		//  and line path calculated in advance of rendering so that we can pass that data to outside drawing functions.
		// We could do away with this and calculate everything at render to save a few passes on very large data sets, but
		// this components API would become less flexible.
		// Note also we'd have to sort somewhere else, as line makes no sense if points aren't sorted
		const lineDefs: EventLineArguments[] = lines.map((line, idx) => {
			const drawnLine = line.line
				.map((p) => ({
					...p,
					drawX: xScale(p.x) + left,
					drawY: yScale(p.y) + top,
				}))
				.sort((a, b) => {
					const v0 = a.drawX;
					const v1 = b.drawX;

					return v1 - v0;
				});

			const path = d3.line<DrawnPoint<Date, number>>(
				({ drawX }) => drawX,
				({ drawY }) => drawY
			)(drawnLine) as string;

			return {
				path,
				line: drawnLine,
				lineIdx: idx,
				// TODO: get better key-generation logic in-place
				lineId: nanoid(),
			};
		});

		return { xScale, yScale, drawHeight, drawWidth, lineDefs };
	}, [lines, width, height, xMargin, yMargin, top, left]);

	useLayoutEffect(() => {
		if (xScale && yScale) {
			if (yAxis) {
				(d3.select(asClass(classNames.yAxis)) as any)
					.attr('transform', `translate(${left}, ${top})`)
					.call(d3.axisLeft(yScale).tickSize(0).tickPadding(6));
			}

			if (xAxis) {
				(d3.select(asClass(classNames.xAxis)) as any)
					.attr(
						'transform',
						`translate(${left}, ${drawHeight + top})`
					)
					.call(d3.axisBottom(xScale).tickSize(0).tickPadding(6));
			}
		}
	}, [xScale, yScale, drawHeight, left, top, xAxis, yAxis, classNames]);

	return (
		<svg width={width} height={height}>
			{lineDefs.reduce((combinedArray, def) => {
				//  we want to draw both points and lines in a single pass, so combine everything
				// into this single array of React Elements
				combinedArray.push(drawLineFn(def));

				// not drawing points for now
				// def.line.forEach((pt) => combinedArray.push(drawPointFn(pt)));

				return combinedArray;
			}, [] as ReactElement[])}
			<g className={classNames.xAxis} />
			<g className={classNames.yAxis} />
			<g>
				<rect
					pointerEvents="all"
					className={classNames.card}
					width={drawWidth}
					height={drawHeight}
					transform={`translate(${left}, ${top})`}
					fill="none"
					onMouseOver={(e) => {
						e.stopPropagation();
						setCardArgs((p) => ({ ...p, visible: true }));
					}}
					onMouseOut={(e) => {
						e.stopPropagation();
						setCardArgs((p) => ({ ...p, visible: false }));
					}}
					onMouseMove={(e) => {
						e.stopPropagation();
						if (xScale && yScale && cardArgs.visible) {
							const [x, y] = d3.pointer(e);
							const [xValue, yValue] = [
								xScale
									.invert(x)
									.toUTCString()
									.split(' ')
									.slice(0, 4)
									.join(' '),
								yScale.invert(y).toFixed(0).toString(),
							];
							setCardArgs((p) => ({
								...p,
								x,
								y,
								xValue,
								yValue,
								drawHeight,
								drawWidth,
							}));
						}

						return null;
					}}
				/>
			</g>
			{drawCardFn(cardArgs)}
		</svg>
	);
};

export default EventLineChart;
