import { GetIndividualsResponse } from '../../features/ontology/types/entityTypes';
import {
	genInRange,
	genNorthAmericanLatLong,
	genPhoneNumber,
	genRandomTimesContacted,
	genTimestamp,
	genUniqueId,
	pickInterval,
} from './helpers';
import { createMockErr, MockHTTPErr } from './mockHTTPErr';
import {
	phoneContact,
	phoneNumber,
	id,
	contactStoreLat,
	contactStoreLong,
	callbackInterval,
	contactStoreLocation,
	timesContacted,
	purchaseCount,
	totalPurchaseValue,
	comment,
	websiteLink,
	firstContactDate,
} from './rawData';
import { makeAlias } from './rawData';
import { faker } from '@faker-js/faker';
import environment from 'common/environment';

const genPhoneContactIndividual = () => ({
	[makeAlias(phoneContact.name, timesContacted.name)]:
		genRandomTimesContacted(),
	[makeAlias(phoneContact.name, id.name)]: genUniqueId(),
	[makeAlias(phoneContact.name, comment.name)]: faker.lorem.paragraph(4),
	[makeAlias(phoneContact.name, phoneNumber.name)]: genPhoneNumber(),
	// not real
	[makeAlias(phoneContact.name, contactStoreLat.name)]: 20,
	[makeAlias(phoneContact.name, contactStoreLong.name)]: 20,
	[makeAlias(phoneContact.name, contactStoreLocation.name)]:
		genNorthAmericanLatLong(),
	[makeAlias(phoneContact.name, callbackInterval.name)]: pickInterval(),
	[makeAlias(phoneContact.name, firstContactDate.name)]: genTimestamp(),
	[makeAlias(phoneContact.name, purchaseCount.name)]: genInRange(1, 5),
	[makeAlias(
		phoneContact.name,
		websiteLink.name
	)]: `https://my-site.com/stuff?paramOne=${faker.random.alphaNumeric(
		27
	)}&paramTwo=${faker.random.alphaNumeric(
		40
	)}&paramThree=${faker.random.alphaNumeric(40)}`,
	[makeAlias(phoneContact.name, totalPurchaseValue.name)]: genInRange(1, 20),
});

export const genMockIndividuals = (recordCount: number) => {
	const result = [];

	for (let i = 0; i < recordCount; i++) {
		result.push(genPhoneContactIndividual());
	}

	return result;
};

const individualDB = new Map<number, Record<string, any>[]>();

// keep quantity of data small in tests for performance
const individualCount = environment.NODE_ENV === 'test' ? 5 : 100;

export const individuals = genMockIndividuals(individualCount);

individualDB.set(phoneContact._id, individuals);

export const getIndividuals = (
	entityId: number
): GetIndividualsResponse | MockHTTPErr => {
	const res = individualDB.get(entityId);

	if (!res) {
		return createMockErr(
			404,
			'Specified entity has no registered individuals'
		);
	}

	return res;
};
