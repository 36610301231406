import { initStore, RootState } from '../../app/store';
import { store } from '../../app/store';
import { BaseUser } from '../../features/authentication/types/userTypes';
import ErrorAlert from '../../features/errorHandling/ErrorAlert';
import PageRouter from '../../features/routing/components/PageRouter';
import {
	getAccountAdmins,
	getAccountGovernors,
	getAccountMembers,
	getDomainMembers,
	getDomainStewards,
} from '../../mocks/mockData/memberManagementData';
import {
	davisAssociates,
	warehouseInventory,
	salesLeads,
	furniture,
	phoneContact,
	plant,
	legCount,
	color,
	preferredClimate,
	flowering,
	contactTime,
	contactLocation,
	callbackInterval,
	phoneNumber,
	timesContacted,
	directContact,
	id,
	contactStoreLat,
	contactStoreLocation,
	contactStoreLong,
	purchaseCount,
	totalPurchaseValue,
	firstContactDate,
	websiteLink,
	comment,
} from '../../mocks/mockData/rawData';
import theme from '../theme/theme';
import { render } from '@testing-library/react';
import { individuals } from 'mocks/mockData/individualData';
import React, { FunctionComponent } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

export type DefaultStore = typeof store;

export const renderWithTheme = (Component: JSX.Element) =>
	render(<ThemeProvider theme={theme}>{Component}</ThemeProvider>);

interface TestProvidersProps {
	store?: DefaultStore;
}

export const TestProviders: FunctionComponent<TestProvidersProps> = ({
	children,
	store,
}) => {
	return (
		<ReduxProvider store={store ? store : initStore()}>
			<BrowserRouter>
				<ThemeProvider theme={theme}>
					{/* render notification modal and its attachment point since many components
						will use it.
					*/}
					<div id="root">
						<ErrorAlert />
						{children}
					</div>
				</ThemeProvider>
			</BrowserRouter>
		</ReduxProvider>
	);
};

export interface TestRenderConfig {
	route?: string;
	storeState?: Partial<RootState>;
}

export const renderWithTestProviders =
	(Component: JSX.Element) => (config?: TestRenderConfig) => {
		const store = initStore(config?.storeState);

		if (config?.route) {
			window.history.pushState({}, '', config.route);
		}

		const tools = render(
			<TestProviders store={store}>{Component}</TestProviders>
		);

		return { store, ...tools };
	};

/**
 *  Same dialect as renderWithTestProviders except that it mounts the dialect page
 * router instead of a single, passed-in component, meaning that it renders
 * the entire application.  HEAVY--use wisely.
 */
export const renderForEndToEnd = (config?: TestRenderConfig) => {
	const store = initStore(config?.storeState);

	if (config?.route) {
		window.history.replaceState({}, '', config.route);
	}

	const tools = render(
		<ReduxProvider store={store}>
			<BrowserRouter>
				<ThemeProvider theme={theme}>
					{/* render notification modal and its attachment point since many components
						will use it.
					*/}
					<div id="root">
						<ErrorAlert />
						<PageRouter />
					</div>
				</ThemeProvider>
			</BrowserRouter>
		</ReduxProvider>
	);

	return { store, ...tools };
};

// quick way to hook top-level element of current page in tests
export const PAGE_TEST_IDS = {
	landing: 'landing',
	appConsole: 'console',
	credentials: 'credentials',
	activity: 'activity',
	model: 'model',
	topLevelLoading: 'top-level-loading',
	authRequestError: 'auth-request-err',
};

/** Responsible for returning ontology objects from test files
 * that are coherent, i.e. whose files actually align with one another.
 * Helpful for tests that make multiple REST requests against mock
 * service worker. This files should always be aligned with files returned
 * by mock REST handlers.
 */
export const getValidOntologyData = () => ({
	account: davisAssociates,
	domains: [salesLeads, warehouseInventory],
	entities: [plant, furniture],
	attributes: [
		legCount,
		color,
		preferredClimate,
		flowering,
		contactTime,
		contactLocation,
		callbackInterval,
		phoneNumber,
	],
	individuals: individuals,
	//  use same account/domain we use in all the 'validPathTo...' helpers
	accountAdmins: getAccountAdmins({
		accountId: davisAssociates._id,
	}) as BaseUser[],
	accountMembers: getAccountMembers({
		accountId: davisAssociates._id,
	}) as BaseUser[],
	accountGovernors: getAccountGovernors({
		accountId: davisAssociates._id,
	}) as BaseUser[],
	domainMembers: getDomainMembers({
		domainId: salesLeads._id,
	}) as BaseUser[],
	domainStewards: getDomainStewards({
		domainId: salesLeads._id,
	}) as BaseUser[],
	validPathToAccount: `/${davisAssociates.name}`,
	validPathToDomain: `/${davisAssociates.name}/${salesLeads.name}`,
	validPathToEntity: `/${davisAssociates.name}/${salesLeads.name}/${phoneContact.name}`,
	validPathToAttribute: `/${davisAssociates.name}/${salesLeads.name}/${phoneContact.name}/${timesContacted.name}`,
	accountInPath: davisAssociates,
	domainInPath: salesLeads,
	entityInPath: phoneContact,
	attributeInPath: timesContacted,
	entitiesForActiveDomain: [directContact, phoneContact],
	attributesForActiveEntity: [
		websiteLink,
		comment,
		phoneNumber,
		id,
		contactStoreLat,
		contactStoreLocation,
		callbackInterval,
		contactStoreLong,
		timesContacted,
		purchaseCount,
		totalPurchaseValue,
		firstContactDate,
	],
});

/**
 * Sometimes we need to wait in tests.  This can help.  Always prefer react-testing-library
 * async utilities to this.
 */
export const delay = (delayInMs: number, value: any = 'done') =>
	new Promise((res) => {
		setTimeout(() => res(value), delayInMs);
	});
