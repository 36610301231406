import HUDModal from './components/HUDModal';
import LeftDrawer from './components/LeftDrawer';
import RightDrawer from './components/RightDrawer';
import BottomBar from './components/BottomBar';
import { FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';

const HUD: FunctionComponent = () => {
	return (
		<>
			<LeftDrawer />
			<BottomBar />
			<HUDModal />
			<RightDrawer />
			<Outlet />
		</>
	);
};

export default HUD;
