import { warnInDev } from 'common/utils/reactUtils';
import { isEmpty } from 'common/utils/typeGuards';
import useDefaultAccount from 'features/authentication/hooks/useDefaultAccount';
import { FunctionComponent } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const FeedPage: FunctionComponent = () => {
	const defaultAccount = useDefaultAccount();
	const location = useLocation();

	if (isEmpty(defaultAccount)) {
		warnInDev(
			'Feed page was reached in error--no default account was available for the authenticated user',
			'error'
		);
		return null;
	}

	const destination = `/${defaultAccount}${location.search}`;

	// Temporary re-route directly to user's account on authorization
	return <Navigate replace to={destination} />;
	// return (
	// 	<main files-testid={PAGE_TEST_IDS.landing}>
	// 		<HorizontalSection>
	// 			<h1>Welcome to the Feed Page!</h1>
	// 		</HorizontalSection>
	// 	</main>
	// );
};

export default FeedPage;
