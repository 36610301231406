import { PAGE_TEST_IDS } from '../../common/utils/testUtils';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const HorizontalSection = styled.div`
	display: flex;
	justify-content: center;
	padding: ${(p) => p.theme.spacing(5)};
`;

const MarketingPage: FunctionComponent = () => (
	<main data-testid={PAGE_TEST_IDS.landing}>
		<HorizontalSection>
			<h1>Welcome to the Marketing Page!</h1>
		</HorizontalSection>
	</main>
);

export default MarketingPage;
