import Heading from '../Heading';
import clsx from 'clsx';
import { FunctionComponent } from 'react';
import styled from 'styled-components';

const StyledHeading = styled(Heading)`
	font-size: 1.5rem * .8;

	text-align: center;
	color: ${(p) => p.theme.palette.oldManGray};

	&.paper-background {
		color: ${(p) => p.theme.palette.lightBaby};
	}
`;

interface FormHeadingProps {
	id: string;
	component: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
	backgroundVariant?: 'default' | 'paper';
}

const FormHeading: FunctionComponent<FormHeadingProps> = ({
	id,
	children,
	component,
	backgroundVariant,
}) => {
	const background = backgroundVariant ? backgroundVariant : 'default';

	return (
		<StyledHeading
			className={clsx(background === 'paper' && 'paper-background')}
			component={component}
			id={id}
		>
			{children}
		</StyledHeading>
	);
};

export default FormHeading;
