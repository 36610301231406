import { UserContext } from 'features/authentication/types/userTypes';

export const baseUserContext: UserContext = {
	interactions: {
		following: false,
	},
	permissions: {
		admin: true,
		edit: true,
		view: true,
	},
	relationships: {},
};
