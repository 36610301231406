import Button from '../../../common/Button';
import { FormBody, FormHeading, FormResults } from '../../../common/Form';
import { warnInDev } from '../../../common/utils/reactUtils';
import { useCreateSourceMutation } from '../../api';
import {
	parseSubmittedCredentials,
	mapDisplaySourceTypeToCredentials,
	renderSourceFormInputs,
} from '../helpers';
import {
	DisplaySourceType,
	CredentialFormValues,
} from '../types/credentialTypes';
import { StyledSourceFormLabel } from './styledComponents';
import Textbox from 'common/Textbox';
import { FunctionComponent, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';

// Requiring either aria-labelledby or aria-label on the form component
// messes up the function signature for 'styled', but shouldn't actually
// cause a problem at runtime.
// @ts-ignore
const StyledForm = styled(FormBody)`
	background: ${(p) => p.theme.palette.background.paper};
	border: none;
`;

const availableSourceTypes: readonly DisplaySourceType[] = [
	'bigquery',
	'sftp',
	's3',
	'google cloud storage',
	'mysql',
	'github',
] as const;

interface SourceFormProps {
	handleClose: () => void;
	accountId: number | null;
}

const SourceForm: FunctionComponent<SourceFormProps> = ({
	handleClose,
	accountId,
}) => {
	const { register, handleSubmit, formState, watch, setValue } =
		useForm<CredentialFormValues>({
			defaultValues: {
				sourceType: 'bigquery',
				displaySourceType: 'bigquery',
				name: '',
				credsSchema: 'service_account',
			},
			shouldUnregister: true,
		});

	const displaySourceType = watch('displaySourceType');

	useEffect(() => {
		const { schema, sourceType } =
			mapDisplaySourceTypeToCredentials(displaySourceType);
		setValue('credsSchema', schema);
		setValue('sourceType', sourceType);
	}, [displaySourceType, setValue]);

	const [createSource, mutationResult] = useCreateSourceMutation();

	const onSubmit: SubmitHandler<CredentialFormValues> = (values) => {
		if (accountId === null) {
			warnInDev(
				'Attempted to submit source creation form when no user ID was available.',
				'error'
			);
			return;
		}

		// NB: this catch statement is primarily for the possbility that reading
		// a submitted file to a files URL will fail.  Failure cases for the
		// network request are handled by 'mutationResult', above.
		// TODO: check if createSource passes network failures/rejections to this catch
		// statement.  Also, display some kind of error to user on a FileReader failure.
		parseSubmittedCredentials(values, accountId)
			.then(createSource)
			.catch((e) => warnInDev('credential parsing failed', 'error', e));
	};

	return (
		<StyledForm
			aria-labelledby="add-source-form"
			onSubmit={handleSubmit(onSubmit)}
		>
			<FormHeading
				backgroundVariant="paper"
				id="add-source-form"
				component="h1"
			>
				Add Source
			</FormHeading>
			<StyledSourceFormLabel htmlFor="select-source-type">
				Select a source type:
			</StyledSourceFormLabel>
			<select
				id="select-display-source-type"
				{...register('displaySourceType', {
					required: {
						value: true,
						message: 'display source required',
					},
				})}
			>
				{availableSourceTypes.map((st) => (
					<option value={st} key={st}>
						{st}
					</option>
				))}
			</select>
			<Textbox
				{...register('name', {
					required: 'name is a required field',
				})}
				error={formState.errors.name}
				labelText="Name"
				key="name"
			/>
			{renderSourceFormInputs(
				displaySourceType,
				register as any,
				formState
			)}
			<>
				<input
					{...register('credsSchema')}
					type="hidden"
					id="creds-schema"
				/>
				<input
					{...register('sourceType', {
						required: true,
					})}
					type="hidden"
					id="source-type"
				/>
			</>
			<Button style={{ marginTop: '16px' }}>Submit</Button>
			<FormResults
				{...mutationResult}
				validationErrors={formState.errors}
				onSuccess={handleClose}
			></FormResults>
		</StyledForm>
	);
};

export default SourceForm;
