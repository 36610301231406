import PopoverListItem from '../../../../common/Popover/PoppoverListItem';
import Spinner from '../../../../common/Spinner';
import { useIdentifyEntityMutation } from '../../../api';
import { parseQueryError } from '../../../api/helpers';
import { BaseAttribute } from '../../../ontology/types/attributeTypes';
import { FunctionComponent, MouseEventHandler } from 'react';

interface IdentifyingListItemProps extends BaseAttribute {
	canEdit: boolean;
}

// If entity has no identifying attribute, clicking an attribute immediately launches
// the 'identify' query.
const IdentifyingListItem: FunctionComponent<IdentifyingListItemProps> = ({
	_id,
	plural: label,
	canEdit,
	entity: { _id: entityId },
}) => {
	const [identify, identifyResults] = useIdentifyEntityMutation();

	const loading = identifyResults.isLoading;

	const onClick: MouseEventHandler = () => {
		if (canEdit) {
			return identify({ entityId, attributeId: _id });
		}

		return;
	};

	const text = identifyResults.error
		? parseQueryError(identifyResults.error).message
		: label;

	return loading ? (
		<Spinner />
	) : (
		<PopoverListItem
			button
			// icon={faCube}
			textContent={text}
			onClick={onClick}
		/>
	);
};

export default IdentifyingListItem;
