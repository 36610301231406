import useHUDIndividual from '../hooks/useHUDIndividual';
import useRightDrawerOpen from '../hooks/useRightDrawerOpen';
import useViewportDimensions from '../hooks/useViewportDimensions';
import ControlBar from './ControlBar';
import IndividualCard from './IndividualCard';
import Paper from 'common/Paper';
import { isNonNullable } from 'common/utils/typeGuards';
import UserManagementPanel from 'features/userManagement/components/UserManagementPanel';
import { FunctionComponent, useRef } from 'react';
import { TransitionStatus, Transition } from 'react-transition-group';
import styled, { DefaultTheme } from 'styled-components';

const short = (theme: DefaultTheme) => theme.transitions.duration.standard;

const ease = (theme: DefaultTheme) => theme.transitions.easing.easeInOut;

const StyledSidePanelContainer = styled(Paper)<{
	viewportHeight: number;
	state: TransitionStatus;
}>`
	opacity: 1;
	transition: transform ${({ theme }) => `${short(theme)}ms ${ease(theme)}`},
		opacity ${({ theme }) => `${short(theme)}ms ${ease(theme)}`};
	transform: ${(p) =>
		`translateX(${
			p.state === 'entered' || p.state === 'entering'
				? -8
				: p.theme.drawerWidth * 2
		}px)`};
	position: absolute;
	width: ${(p) => `${p.theme.drawerWidth * 2}px`};
	height: ${(p) =>
		`${p.viewportHeight - p.theme.navHeight - p.theme.panelGutter}px`};
	top: ${(p) => `${p.theme.navHeight}px`};
	right: 0;
	display: flex;
	flex-direction: column;
	overflow: visible;
	z-index: ${(p) => p.theme.zIndex.controlSurface};
	border: 1px solid ${(p) => p.theme.palette.divider};
	border-right: 0;
	padding: 10px 5px 5px;
`;

const RightDrawer: FunctionComponent = () => {
	// Need this ref to stop react-transition-group from trying to
	// use findDOMNode, which is deprecated and profoundly irritating.
	const transitionRef = useRef<any>();

	const { viewportHeight } = useViewportDimensions();

	const drawerIsOpen = useRightDrawerOpen();

	const activeIndividual = useHUDIndividual();

	return (
		<Transition in={drawerIsOpen} timeout={10} nodeRef={transitionRef}>
			{(state) => (
				<StyledSidePanelContainer
					ref={transitionRef}
					viewportHeight={viewportHeight}
					state={state}
					data-testid="hud-right-drawer-container"
					className="side-container"
				>
					{/* Need vertical scroll for member panel, and for some reason setting
                    overflow-y on StyledSidePanelContainer hides translated control bar, so we put
                    the scroll on an inner container instead. */}
					<div style={{ overflowY: 'auto' }}>
						<ControlBar />
						{isNonNullable(activeIndividual) ? (
							<IndividualCard
								datum={activeIndividual.datum}
								attributes={activeIndividual.attributes}
							/>
						) : (
							<UserManagementPanel />
						)}
					</div>
				</StyledSidePanelContainer>
			)}
		</Transition>
	);
};

export default RightDrawer;
