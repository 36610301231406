import { warnInDev } from '../../common/utils/reactUtils';
import { hasOwnProperty } from '../../common/utils/typeUtils';
import { AppError } from '../errorHandling/types/errorTypes';
import {
	AccountAuthorityLevel,
	DomainAuthorityLevel,
} from '../userManagement/types/MemberManagementTypes';
import {
	QueryErrResult,
	NonnullQueryErrResult,
	QueryHTTPRejection,
	QueryCustomError,
	QueryParsingError,
	QueryHTTPRejectionWithMessage,
} from './types';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

export const isResponseCode = (code: number) => (err: QueryErrResult) => {
	if (isQueryHTTPRejection(err)) {
		return err.status === code;
	}

	return false;
};

export const is403 = isResponseCode(403);

export const isNonNullableQueryErrResult = (
	e: QueryErrResult
): e is NonnullQueryErrResult => typeof e !== 'undefined';

const isFetchBaseQueryError = (e: QueryErrResult): e is FetchBaseQueryError => {
	if (isNonNullableQueryErrResult(e)) {
		if (hasOwnProperty(e, 'status')) {
			return true;
		}
	}

	return false;
};

export const isQueryHTTPRejection = (
	e: QueryErrResult
): e is QueryHTTPRejection => {
	if (isFetchBaseQueryError(e)) {
		return typeof e.status === 'number';
	}

	return false;
};

export const isCustomError = (e: QueryErrResult): e is QueryCustomError => {
	if (isFetchBaseQueryError(e)) {
		return e.status === 'CUSTOM_ERROR';
	}

	return false;
};

export const isQueryParsingError = (
	e: QueryErrResult
): e is QueryParsingError => {
	if (isFetchBaseQueryError(e)) {
		return e.status === 'PARSING_ERROR';
	}

	return false;
};

export const isQueryFetchError = (
	e: QueryErrResult
): e is QueryParsingError => {
	if (isFetchBaseQueryError(e)) {
		return e.status === 'FETCH_ERROR';
	}

	return false;
};

export const isHTTPUnauthorizedErr = (u: QueryErrResult): boolean => {
	if (isQueryHTTPRejection(u)) {
		return u.status === 401;
	}

	return false;
};

const isErrorWithMessage = (
	err: QueryHTTPRejection
): err is QueryHTTPRejectionWithMessage =>
	Boolean(
		err.data &&
			err.data !== null &&
			typeof err.data === 'object' &&
			hasOwnProperty(err.data, 'message')
	);

export const parseQueryError = (
	queryError: Exclude<QueryErrResult, undefined>
): AppError => {
	warnInDev('Query failure', 'error', queryError);

	let message: string;

	if (isQueryHTTPRejection(queryError)) {
		if (isErrorWithMessage(queryError)) {
			message = queryError.data.message as string;
		} else {
			message = `Request for remote data completed, but server rejected the request with response code ${queryError.status}`;
		}
	}

	if (isCustomError(queryError)) {
		message = queryError.error;
	}

	if (isQueryParsingError(queryError)) {
		message =
			'Request for remote files completed, but application was unable to parse response files, likely due to a problem with response type headers.';
	}

	if (isQueryFetchError(queryError)) {
		message =
			'Unable to complete network request.  View network log for details.';
	}
	// TODO: clean up this ignore statement
	// @ts-ignore
	return new AppError(message as string);
};

export const extractQueryErrMessage = (
	queryError: Exclude<QueryErrResult, undefined>
): string => parseQueryError(queryError).message;

interface LoadStateTracker {
	isLoading: boolean;
	isFetching?: boolean;
	isUninitialized: boolean;
	isSuccess: boolean;
}

interface ErrorStateTracker {
	isError: boolean;
	error?: QueryErrResult;
}

/**
 * Accepts 1 or more result objects from an RTK query or mutation, and returns true if any of them
 * are in a loading/fetching state.
 */
export const mergeLoadingStates = (...states: LoadStateTracker[]) =>
	states.some(({ isLoading, isFetching }) => isLoading || isFetching);

/**
 * Accepts 1 or more result objects from an RTK query or mutation, and
 * return an instance of QueryErrResult.  Note that this type includes
 * undefined--this will be the case if no result is in an error state.
 */
export const mergeErrorStates = (...states: ErrorStateTracker[]) =>
	states.find(({ isError }) => isError)?.error;

export type QueryStateTracker = LoadStateTracker & ErrorStateTracker;

export const concatQueryStates = (
	a: QueryStateTracker,
	b: QueryStateTracker
): QueryStateTracker => ({
	isLoading: a.isLoading || b.isLoading,
	isUninitialized: a.isUninitialized || b.isUninitialized,
	isFetching: !!(a.isFetching || b.isFetching),
	isError: a.isError || b.isError,
	error: a.error || b.error,
	isSuccess: a.isSuccess && b.isSuccess,
});

const zeroQueryState: QueryStateTracker = {
	isLoading: false,
	isUninitialized: false,
	isFetching: false,
	isError: false,
	isSuccess: true,
};

/**Fold the loading state of an arbitrary number of RTK query results into a single result. QueryStateTracker is a monoid. */
export const mergeQueryStates = (...args: QueryStateTracker[]) =>
	args.reduce((acc, next) => concatQueryStates(acc, next), zeroQueryState);

/**
 * Construct tag for RTK Query 'invalidates tags' for member mgmt mutation endpoints.
 */
export const memberMgmtInvalidator = (
	parentObject: 'account' | 'domain',
	role: AccountAuthorityLevel | DomainAuthorityLevel
) => {
	if (parentObject === 'account') {
		if (role === 'admin') {
			return 'accountAdmins';
		}
		if (role === 'governor') {
			return 'accountGovernors';
		}
		if (role === 'member') {
			return 'accountMembers';
		}
	}

	if (role === 'steward') {
		return 'domainStewards';
	}
	return 'domainMembers';
};
