import App from './app/App';
import environment from './common/environment';
// Currently all this does is bring in a CSS normalizer that
// comes along with CRA
import './css/index.css';
import * as serviceWorker from './serviceWorker';
import React from 'react';
import ReactDOM from 'react-dom';

// this worker stubs out network requests when app is running
// in development mode
const { NODE_ENV, USE_API_SERVICE_WORKER } = environment;
if (NODE_ENV === 'development' && USE_API_SERVICE_WORKER === 'true') {
	import('./mocks/browser');
}

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
